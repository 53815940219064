@use "theme";

@mixin hover {
  &:not([disabled]):hover {
    @content;
  }
}

@mixin disabled {
  &[disabled] {
    @content;
  }
}

@mixin _tray(
  $_background-colour: theme.$grey-lightest,
  $_background-colour-alt: theme.$white,
  $_colour: theme.$grey-dark,
  // dark mode
  $_background-colour-dark-mode: theme.$grey-darker,
  $_background-colour-dark-mode-alt: theme.$grey-darkest,
  $_colour-dark-mode: theme.$white,
  $_colour-dark-mode-alt: theme.$grey-lighter
) {


  // theme
  background-color: $_background-colour;
  color: $_colour;
  border-left: 1px solid theme.$grey-lighter;
  border-right: 1px solid theme.$grey-lighter;
  user-select: none;

  // size/position
  min-height: 100%;
  min-width: 64px;
  width: 64px;
  // layout
  display: grid;
  //display: inline-grid;
  grid-row-gap: 12px;
  align-content: start;
  justify-items: center;
  padding: 16px 0;
  // TODO scroll overflow

  overflow-y: auto;
  overflow-x: hidden;

  // font
  @include theme.type-body-12;
  @include theme.dark-mode {
    background-color: $_background-colour-dark-mode;
    color: $_colour-dark-mode;
    border-color: theme.$grey-dark;
  }

  @include theme.apps-scrollbar--hidden;

  &.white-background {
    background-color: $_background-colour-alt;
    @include theme.dark-mode {
      background-color: $_background-colour-dark-mode-alt;
      border-right: 1px solid theme.$grey-dark;
      border-left: 1px solid theme.$grey-dark;
    }
  }
}

@mixin _tray-section() {
  display: flex;
  flex-flow: column;
  gap: 8px;

  > .title {
    @include theme.type-body-12;

    //color: $_colour;
    //@include theme.dark-mode {
    //  color: $_colour-dark-mode-alt;
    //}

    display: inline-block;
    text-align: center;
    //width: 100%;
    //background-color: blue;
    //margin: 12px 0;
  }
}

@mixin _tray-item(
  $_background-colour: theme.$tangerine,
  $_border-colour: theme.$grey-dark,
  $_colour: theme.$tangerine-contrast
) {
  @include theme.type-body-12;
  font-weight: 700;
  //background-color: green;
  margin: 0;
  width: 48px;
  height: 48px;
  //line-height: 48px;
  padding: 0;
  border: 4px solid $_background-colour;
  position: relative;
  //@include theme.shadow-level-3;

  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;

  // TODO @include theme.apply-background-and-contrasts();

  background-color: $_background-colour;
  color: $_colour;
  @include theme.dark-mode {
    //background-color: $_background-colour;
    //color: $_colour;
  }

  &.tray-icon-button {
    font-size: 18px; // 16px in Figma
  }

  span {
    text-align: center;
  }

  span.notificationsTag {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 18px;
    height: 18px;
    line-height: 18px;
    background-color: theme.$orange;
    color: theme.$orange-contrast;
    border-radius: 100%;
  }

  &.shape-rounded {
    border-radius: 12px;
    //border-radius: 8px 8px 24px 24px;
    //padding-bottom: 6px; // push writing slightly higher given larger bottom rounding
  }

  &.shape-circle {
    border-radius: 100%; // circular button
  }

  &.shape-square {
    border-radius: 2px;
    //background-color: theme.$orange;
    //color: theme.$orange-contrast;
  }

  &.theme-grey_light {
    background-color: theme.$grey-light;
    color: theme.$grey-light-contrast;
    border-color: theme.$grey-light;
  }

  &.theme-grey_lighter {
    background-color: theme.$grey-lighter;
    color: theme.$grey-lighter-contrast;
    border-color: theme.$grey-lighter;

    @include theme.dark-mode {
      background-color: transparent;
      color: theme.$grey;
      border-color: theme.$grey;
      border-width: 2px;
      //background-color: theme.$grey-lighter;
      //border-color: theme.$white; // theme.$grey-lighter;

      &.active {
        color: theme.$grey-lighter;
        border-color: theme.$grey-lighter;
      }
    }
  }

  &.theme-orange {
    background-color: theme.$orange;
    color: theme.$orange-contrast;
    border-color: theme.$orange;
  }

  &.theme-yellow {
    background-color: theme.$yellow;
    color: theme.$yellow-contrast;
    border-color: theme.$yellow;
  }

  &.theme-tangerine {
    background-color: theme.$tangerine;
    color: theme.$tangerine-contrast;
    border-color: theme.$tangerine;
  }

  &.theme-rose {
    background-color: theme.$rose;
    color: theme.$rose-contrast;
    border-color: theme.$rose;

    span.notificationsTag {
      background-color: theme.$yellow;
      color: theme.$yellow-contrast;
    }
  }

  &.active {
    border: 4px solid $_border-colour;
    @include theme.dark-mode {
      border-color: theme.$white; // theme.$grey-lighter;
    }
  }

  @include hover {
    cursor: pointer;
    border: 4px solid $_border-colour;
    @include theme.dark-mode {
      border-color: theme.$white; // theme.$grey-lighter;
    }
  }

  @include disabled {
    // TODO disabled status & attribute
  }
}

.ux-tray {
  @include _tray;

  .ux-tray-section {
    @include _tray-section;
  }

  .ux-tray-item {
    @include _tray-item;
  }
}
