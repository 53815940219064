@mixin date-column-type() {
    &.date {
        border: 1px solid theme.$grey-light;

        .date-field-container {
            position: relative;
            bottom: 0px;

            .date-picker {
                //position: absolute; // cant use this with non fixed cell sizes
                //left: 0px;
                //bottom: 0px;

                .date-picker-closed {
                    display: grid;
                    grid-template-columns: 88px $_cell_height; // 86-90 px is good, might need more if we do a longer date format in future

                    input {
                        @include theme.type-body-12;

                        //padding-left: 10px;
                        background-color: transparent;
                        height: $_cell_height;
                        line-height: $_cell_height;
                        text-align: center;
                        //width: 84px;

                        &:focus,
                        &:focus-visible {
                            border: none;
                            outline: none;
                        }
                    }
                }

                .calendar-container {
                    // TODO this should probably be positioned in react "over" the tables etc with position: fixed and top/left mouse location
                    position: absolute;
                    left: $_cell_width;
                    top: 0px;
                    //right: 170px;
                    //top: 0px;
                }
            }

        }
    }
}

@mixin number-editable() {
    &.editable {
        min-width: $_cell_width; // avoids columns getting large when numbers are edited
    }
}

@mixin input-column-type() {
    &.ux-input {
        max-width: 100%;

        .icon-button {
            position: relative;
            padding: 0px 1px;
            width: 12px;
        }

        >input {
            max-width: calc($_cell_width - $_cell_horizontal_padding*2 - $_cell_height/2); // cell minus padding minus the nullify button (12px wide)
            padding: 0;
            width: 100%;
        }
    }
}

@mixin input-text-column-type() {
    &.text {
        >* {
            &.ux-input {
                max-width: unset;

                >input {
                    min-width: calc($_cell_width - $_cell_horizontal_padding*2 - $_cell_height/2); // cell minus padding minus the nullify button (12px wide)
                }
            }
        }
    }
}

@mixin select-column-type() {
    &.ux-select {
        padding: 0 $_cell_horizontal_padding;
        line-height: $_cell_height;
        height: $_cell_height;
        text-align: end;

        &:hover {
            background-color: theme.$grey-lighter;
        }

        button[role=combobox] {
            width: 100%;
            padding: 0;
            line-height: $_cell_height;
            height: $_cell_height;
            gap: $_cell_horizontal_padding;
            align-items: normal;

            &:hover {
                background-color: unset;
            }

        }
    }

}


@mixin column-datatype() {
    @include date-column-type;
    @include number-editable;
    @include input-text-column-type;

    >* {
        @include select-column-type;
        @include input-column-type;
    }
}