@use "theme";

@mixin field() {
    .logo { @include logo; }

}

@mixin logo() {
    display: flex;
    flex-direction: row;
    flex: 1;
    gap: 5px;
    align-items: flex-start;
    margin-top: 6px;

    .image {

        display: flex;
        align-items: center;
        align-content:space-around;
        justify-content: space-around;
        flex-direction: column;

        img{
            // 6px lines up logo with title
            margin-top: 6px;
            width: 50px;
            height: 20px;
        }
    }

    .crumb {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

    .name {
        color: black;
        text-align: left;
        font-size: 18px;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
        //margin: auto 10px;
        // padding-left: 10px;
    }
}

@include field();
