@use "theme";

.ux-template-summary {
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	border: 1px solid #DEDEDE;
	font-size: 12px;
	line-height: 1.4em;

	&.selected {
		border: 1px solid theme.$orange-light;
		box-shadow: 0 0 10px -5px theme.$orange-light;
	}

	.label {
		font-weight: 800;
		padding-bottom: 0.5rem;
	}

	.ftname {
		font-weight: 800;
	}

	.filename {}

	.ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	.action-container {
		display: flex;
		flex-direction: column;
		gap: 0.5rem
	}

	.justify-between {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}


	// minic the "button" container so it aligns with the download button
	.ui-icon {
		display: flex;
		width: 24px;
		justify-content: center;
		align-items: center;
	}
}