.applications {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 43px 1fr auto;

  .header {
    padding: 0rem 13px;
    display: flex;
    flex-direction: row;
    gap: 13px;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid lightgrey;
  }

  .container {
    padding: 20px;
    background-color: #f3f3f3;
    overflow-y: auto;

    div[role="grid"] {
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="gridcell"], div[role="columnheader"] { // do the same on the header and cols
            gap: .5rem; // split buttons/icons apart from text

            // first col wider for text heavy content
            &:nth-of-type(1)
            {
              flex-grow: 2;
            }

            // last column of "Actions"/button smaller/centered
            &:last-of-type
            {
              flex-grow: .3;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .container-roles {
    padding: 20px;
    background-color: #f3f3f3;
    overflow-y: auto;

    div[role="grid"] {
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="gridcell"], div[role="columnheader"] { // do the same on the header and cols
            gap: .5rem; // split buttons/icons apart from text

            // first col wider for text heavy content
            &:nth-of-type(1)
            {
              flex-grow: 1;
            }

            // last column of "Actions"/button smaller/centered
            &:last-of-type
            {
              flex-grow: 1;
              justify-content: inherit;
            }
          }
        }
      }
    }
  }

}
