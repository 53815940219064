@use "theme";

.hierarchical-filter {
  color: theme.$grey-dark;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  .hierarchical-select {
    display: flex;
    flex-direction: row;
  }

  .pr-1 {
    padding: 0 0.25rem;
  }
}