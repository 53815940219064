@use "theme";

@mixin _panel() {
  @include theme.type-body-14;

  > .tiles {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    // tiles minimum width 240px
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }

  > .title {
    @include theme.type-body-16-bold;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.ux-panel {
  @include _panel();
}
