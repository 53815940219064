@use "theme";

div.icon-button,
button.icon-button {
  background: none;
  border: none;
  border-radius: 100%;
  padding: 0;

  //aspect-ratio: 1 / 1;
  $_size: 24px;
  font-size: calc($_size / 2);
  width: $_size;
  line-height: $_size;
  height: $_size;
  overflow: hidden;

  display: flex;
  //align-content: center;
  //align-items: center;
  justify-content: center;
  justify-items: center;
  //text-align: center;
  //vertical-align: center;

  //margin-right: 8px;
  //margin: 0 4px;

  @include theme.dark-mode {
    color: theme.$grey-lightest;
  }

  &:hover {
    cursor: pointer;

    &.icon-button-fill {
      background-color: theme.$grey-lighter;
    }

    @include theme.dark-mode {
      color: theme.$grey-darkest;
    }
  }
}
