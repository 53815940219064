@use "theme";

// TODO @RM - improve font stuff
.tab-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;

  &.tab-menu-header {
    align-self: stretch;
    .tab-menu-item {
      background-color: transparent;
      color: inherit;
      height: max-content;

      display:flex;
      justify-content: center;
      align-items: center;
      height: 43px;
      
      ::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        background-color: theme.$brand-primary;
        display: block;
        height: 3px;
        border: 1px solid transparent;
        border-radius: 2px;
        bottom: 1px;
        left: 0;
        padding: none;
        transform: scaleX(0);
        transform-origin: bottom center;
        transition: transform 0.2s ease-in-out;
      }

      &.selected, &.selected:hover {
        background-color: inherit;
        color: inherit;
        ::after {
          transform: scaleX(1);
          background-color: theme.$brand-primary;
        }
      }

      &:hover {
        ::after {
          transform: scaleX(0.8);
          background-color: theme.$brand-accent;
        }
      }      
    }
  }

  .tab-menu-item {
    height: 40px;
    width: 140px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1rem;

    display: inline-block;
    position: relative;
    // &:not(:hover) {
    //   text-overflow: ellipsis;
    // }

    // &:hover,
    // &:focus {
    //   span {
    //     display: inline-block;
    //     animation-name: scroll-text;
    //     animation-duration: 7s;
    //     animation-timing-function: linear;
    //     animation-delay: 0s;
    //     animation-iteration-count: infinite;
    //     animation-direction: normal;
    //     /* FYI this would be the shorthand:
    //       animation: scroll-text 5s ease 0s 2 normal;
    //     */
    //   }
    // }

    // @keyframes scroll-text {
    //   0% {
    //     transform: translateX(0%);
    //   }
    //   90% {
    //     transform: translateX(-100%);
    //   }
    //   95% {
    //     transform: translateX(0%);
    //   }
    //   100% {
    //     transform: translateX(0%);
    //   }
    // }
    
    background-color: theme.$grey-lighter;
    color: theme.$grey-lighter-contrast;

    &.selected {
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
    }
  }
}