@use "theme";

.radio {
  align-items: center;
}

.error {
  color: red;
}

.sync-block {

  padding-bottom: 1rem;

  .sync-title-control {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    margin-bottom: 0.5rem;
    justify-content: space-between;

    .sync-title {
      font-size: 16px;
      font-weight: bold
    }
  }

  .sync-table {
    width: 100%;
    padding: 0.5rem;
    border-collapse: collapse;

    .sync-table-head {
      background-color: theme.$grey-light;
      text-align: left;
    }

    .cell {
      border: 1px solid black;
      padding: 4px;
    }
  }
}