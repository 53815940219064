.organisation-card {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  gap: 1rem;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, .132);
  margin-top: 20px;

  .organisation-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem

  }
}