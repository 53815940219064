@use "theme";

.ux-activity-log {
  padding: 0.5rem;
  gap: 1rem;
  font-size: 12px;
  border-left: 1px solid #DEDEDE;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
}

.steps-item-map {
  :last-child {
    .steps-item-tail {
      display: none;
    }
  }
}

.steps-item {
  display: block;
  flex: 1 0 auto;
  padding-inline-start: 0;
  overflow: visible;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;

  .steps-item-tail {
    display: block;
    position: absolute;
    top: 24px;
    inset-inline-start: 11px;
    width: 1px;
    height: calc(100% - 24px);
    background-color: grey;

    &.no-icon {
      top: 0px;
      height: 100%
    }
  }

  .steps-item-icon {
    float: left;
    background-color: theme.$grey-light;
    border-color: #e6f4ff;
    width: 26px;
    height: 26px;
    margin-top: 0;
    margin-bottom: 0;
    margin-inline-start: 0;
    margin-inline-end: 12px;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
    line-height: 24px;
    text-align: center;
    border-radius: 24px;
    border: 1px solid transparent;
    transition: background-color .3s, border-color .3s;
  }

  .steps-item-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    
    &.no-icon {
      padding-left: 36px;
    }
    
    .accent {
      color: theme.$orange;
    }
    
    .error {
      color:red;
      border: 1px dashed red;
      padding: 0.5rem;
      &::before {
        content: 'Error: '
      }
    }

    .pointer {
      cursor: pointer;
    }

    .semi-bold {
      font-weight: 600;
    }

    .steps-item-byline {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 0.3rem;
      flex-wrap: wrap;

      .byline-title {
        display: flex;
        flex-direction: row;
        column-gap: 0.3rem;
        flex-wrap: wrap;
        align-content: flex-start;

        >div {
          //white-space: nowrap;
          line-height: initial;
          font-weight: 600;
          height: min-content;
        }
      }

      .byline-date {
        font-weight: 400;
        font-style: italic;
        text-align: right;
      }
    }

    .steps-item-section {
      margin: 0.25rem 0 0 0.25rem;
      padding: 0.25rem 0.5rem;
      //border: 1px solid theme.$grey-light;
      background-color: theme.$grey-lightest;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      white-space: pre-line;

      >* {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .steps-item-filename {
        // width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
      }

      .action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
    }

    .blurb-input {
      margin: 0.25rem 0 0.25rem 1rem;
      padding: 0.25rem 0.5rem;
      resize: none;
      min-height: 3rem;
      max-height: 14rem;
      font-family: "Helvetica Neue", "Helvetica Neue Light", sans-serif;

      &:placeholder-shown {
        font-style: italic;
      }
    }

    .action-buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: flex-end;
      padding: 0.5rem 0;
    }
  }
}