.ux-templates-layout {
	display: grid;

	&.min {
		grid-template-columns: 1fr;
		overflow-y: auto;
	}

	&.max {
		grid-template-columns: 2fr 3fr 4fr;
		max-height: calc(100vh - 71px);
		overflow: hidden;
		
		.ux-templates {
			overflow-y: auto;
		}
	}
}

.ux-templates {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0.5rem;

	.ux-collapsible {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		padding: 0;
		padding-bottom: 1rem;

		.collapsible-content {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			padding: 0;

		}
	}

	.tabs {
		display: flex;
		flex-direction: row;
		column-gap: 0.5rem;
		font-size: 12px;
		flex-wrap: wrap;

		:last-child {
			display: none;
		}

		.tab {
			font-style: italic;

			&:hover,
			&.selected {
				cursor: pointer;
				font-weight: 800;
				text-decoration: underline;
			}
		}

		.tab::before {
			display: block;
			content: attr(title);
			font-weight: 800;
			text-decoration: underline;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
	}

	.search-box .search-string {
		width: 100%;
	}

	.file-count {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		font-weight: 800;
	}
}

.entity-template-selector {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0.5rem;
	border-right: 1px solid #DEDEDE;

	// might be good for all selects, but for now just want to fix the one on the templates page
	.ux-select button[role=combobox] {
		height: unset;
		line-height: unset;
	}

}