
$font-name--helvetica-neue: "Helvetica Neue";
//$font-family--helvetica-neue: "Helvetica Neue", Helvetica, Arial, sans-serif;

//@mixin font-family--helvetica-neue {
//  font-family: $font-family--helvetica-neue;
//}

@mixin font-face--helvetica-neue {
  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    src: url('../../public/assets/fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2') format('woff2'),
    url('../../public/assets/fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff') format('woff'),
    url('../../public/assets/fonts/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf') format('ttf'),
    url('../../public/assets/fonts/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot') format('eot');
  }
  @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: bold;
    src:  url('../../public/assets/fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2') format('woff2'),
          url('../../public/assets/fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff') format('woff'),
          url('../../public/assets/fonts/f07c25ed-2d61-4e44-99ab-a0bc3ec67662.ttf') format('ttf'),
          url('../../public/assets/fonts/4c21ab66-1566-4a85-b310-fbc649985f88.eot') format('eot');
  }
  @font-face {
    font-family: 'Helvetica Neue Light';
    font-style: normal;
    src:  url('../../public/assets/fonts/f9c5199e-a996-4c08-9042-1eb845bb7495.woff2') format('woff2'),
          url('../../public/assets/fonts/2a34f1f8-d701-4949-b12d-133c1c2636eb.woff') format('woff'),
          url('../../public/assets/fonts/fdf10d5e-4ce0-45e5-a6c1-5eaff6cb1c11.ttf') format('ttf'),
          url('../../public/assets/fonts/cb0d1e8a-e111-4dbf-82c2-e948aba954c0.eot') format('eot');
  }
}
