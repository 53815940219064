@use "theme";

$_border-radius: 4px;

@mixin table() {
  @include theme.type-body-12;
  background-color: white;
  padding: 16px;
  display: grid; // inline-grid;
  gap: 8px;
  align-content: flex-start;
  max-width: 100%;

  .columns-container {
    max-width: 100%;
    max-height: 70vh;
    justify-items: flex-start;
    justify-content: flex-start;
    overflow: auto;

    // overflow-x: auto;
    .columns {
      width: 100%;
      position: sticky;
      left: 0;
      z-index: 2000;
      background: white;

      &:first-child {
        .ux-column {
          &:first-child {

            // each col/cell
            >* {

              // left top
              &:first-child {
                border-top-left-radius: $_border-radius;
              }

              // left bottom
              &:last-child {
                border-bottom-left-radius: $_border-radius;
              }
            }
          }
        }
      }

      &:last-child {
        .ux-column {
          &:last-child {

            // each col/cell
            >* {

              // right top
              &:first-child {
                border-top-right-radius: $_border-radius;
              }

              // right bottom
              &:last-child {
                border-bottom-right-radius: $_border-radius;
              }
            }
          }
        }

      }
    }

    .columns-scroller {
      @extend .columns;
      position: inherit;
      z-index: inherit;

      padding-bottom: 1px; // seems to fix ugly second scrollbar issue
      padding-right: 1px; // seems to fix ugly second scrollbar issue
    }

    .columns-scroller-nopinned {
      @extend .columns-scroller;
    }
  }

  .rows-container {
    max-width: 100%;
    max-height: 70vh;
    justify-items: flex-start;
    justify-content: flex-start;
    overflow: auto;

    display: flex;

    .rows {
      width: 100%;

      position: sticky;
      top: 0;
      z-index: 2000;
      background: white;


      &:first-child {
        .ux-column {
          &:first-child {

            // each col/cell
            >* {

              // left top
              &:first-child {
                border-top-left-radius: $_border-radius;
              }

              // right top
              &:last-child {
                border-top-right-radius: $_border-radius;
              }
            }
          }
        }
      }

      &:last-child {
        .ux-column {
          &:last-child {

            // each col/cell
            >* {

              // left bottom
              &:first-child {
                border-bottom-left-radius: $_border-radius;
              }

              // right bottom
              &:last-child {
                border-bottom-right-radius: $_border-radius;
              }
            }
          }
        }

      }

    }

    .rows-scroller {
      @extend .rows;

      position: inherit;
      z-index: inherit;

      max-height: 300px;
      //overflow-x: clip; // prevents calendar popup being scrolled too if calendar cell is too far right
      //overflow-y: auto;
      padding-bottom: 1px; // seems to fix ugly second scrollbar issue
      padding-right: 3px; // seems to fix ugly second scrollbar issue and pinned drifting 2px out of alignment with nonpinned
    }

    .rows-scroller-nopinned {
      @extend .rows-scroller;
      overflow: auto;
    }

  }

  >.title {
    @include theme.type-body-14-bold;
  }

  >.footer {
    @include theme.type-body-14-bold;
  }

  // > .caption,
  // > .description {
  // }

  >.actions {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
  }
}

.ux-table {
  @include table();
}

.loadingTable, .invalidTable {
  padding: 1rem;
  display: flex;
  font-size: 15px;
}