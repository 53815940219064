.form-wrapper {
  min-height: 100%; 
  display: grid;
  grid-template-rows: 1fr calc(28px + 2rem);
}

.form-action-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 1rem 0;
}

.pictogram {
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 120px;
  margin: 10px 0px;
}