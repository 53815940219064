.breadcrumbs {
  padding: 0 10px;
  gap: 0;

  .breadcrumb {
    color: black;
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    cursor: pointer;
  }
}