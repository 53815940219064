@use "theme";

@mixin ux-select-portal {
  // font
  @include theme.type-body-12;
  line-height: 1;

  // theme
  //background-color: theme.$grey-lighter;
  background-color: white;
  //border: 1px solid black;
  border: none;
  box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, .132),
  0 0.6px 1.8px 0 rgba(0, 0, 0, .108);
  border-radius: 4px;
  z-index: 2002;
  
  // position
  overflow-y: auto;
  padding: 8px;
  max-height: 300px;

  div.label {
    padding: 6px
  }

  div.field-input {
    height: 26px !important;
    background: theme.$grey-lightest;
    border-radius: 5px;
    margin-bottom: 0.5rem;
  }

  div[role=option] {
    all: unset;
    padding: 0 8px;
    display: flex;
    height: 20px;
    position: relative;
    align-items: center;
    user-select: none;

    gap: 0.5rem;

    .select-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      flex: 1;
    }
  
    &.hidden {
      display: none;
    }

    &[data-state=checked],
    &:hover {
      cursor: pointer;
      background-color: theme.$grey-darker;
      color: white;
    }
    &[data-highlighted] {
      outline: none;
      background-color: theme.$grey-darker;
      color: white;
    }

    &[data-disabled] {
      color: theme.$yellow;
      pointer-events: none;
    }

    button[role=checkbox] {
      all: unset;
      background-color: white;
      width: 17px;
      height: 17px;
      min-width: 17px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: theme.$orange;
      
      border: 1px solid theme.$grey-lighter;
      
      &:hover {
        border: 1px solid theme.$orange;
      }
    }
  }

  .select-action-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 10px;

    .select-action-button {
      color: theme.$grey;
      background-color: white;
      padding: 10px;

      &:hover {
        background-color: theme.$grey-lightest;
      }
    }
  }
}

@mixin ux-select {

  button[role=combobox] {
    all: unset;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 20px);
    padding: 0 10px;
    line-height: 1;
    height: 24px;
    gap: 5px;
    color: theme.$black;
    
    &.selectionMade {
      font-weight: 600;
      color: theme.$grey-darkest;
    }

    &:hover {
      background-color: theme.$grey-lighter;
    }

    &:focus {
      box-shadow: none;
    }

    &[data-placeholder] {
      color: theme.$black;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .badge {
      background: theme.$grey-lightest;
      font-size: 13px;
      line-height: 16px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      font-weight: bold;
      margin-right: 5px;
    }

  }
}

.ux-select-portal-vanilla {
  @include ux-select-portal();
}

.ux-select-vanilla {
  @include ux-select();

  &.vanilla {
    border: 1px solid theme.$grey-light;
  }
}
.ux-interact-pane .ux-filters .ux-filter .control {
  border: 1px solid theme.$grey-lighter;

  .ux-select button:not(.selectionMade) span {
    color: white;
  } 
}
