@use "theme";

.app-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background: white;
  padding: 0rem 13px;
  border-bottom: 1px solid lightgrey;
}

.ux-app {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns:
    min-content
    minmax(320px, 1fr)   // TODO provide min-width
    min-content
    min-content
  ;

  &.min {
    grid-template-columns:
      min-content
      minmax(340px, 1fr)   // TODO provide min-width
      minmax(340px, 4fr)   // TODO provide min-width
      min-content
      min-content
    ;
  }

  &.max {
    grid-template-columns:
      min-content
      minmax(340px, 1fr)   // TODO provide min-width
      0
      min-content
      min-content
    ;
    > div {
      &.content {
        display: none;
      }
    }
  }

}