@use "theme";

.landing {
  width: 100vw;
  height: 100vh;
  position: relative;

  .background-wrapper {
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url("../../../public/assets/images/large/GettyImages-83314261-large.webp");
    background-size: cover;
    //filter: blur(5px);
    // TODO filter transition
  }

}
