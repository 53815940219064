div[role="grid"] {
  div[role="rowgroup"] {
    div[role="row"] {
      div[role="gridcell"], div[role="columnheader"] { // do the same on the header and cols
        gap: .5rem; // split buttons/icons apart from text

        // first col wider for text heavy content
        &:nth-of-type(1)
        {
          flex-grow: 2;
        }

        // last column of "Actions"/button smaller/centered
        &:last-of-type
        {
          flex-grow: .3;
          justify-content: center;
        }
      }
    }
  }
}