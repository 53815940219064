@use "theme";

.marketing-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 0 4rem 2rem 4rem;
  overflow-y: auto;

  .main-panel {
    display: grid;
    grid-template-rows: min-content min-content 1fr;

    .title {
      width: 100%;
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
      padding: 2rem;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
    }

    .subtitle {
      padding: 0 2rem;
      width: 100%;
      font-size: 16px;
      font-weight: 600;
    }

    .main-panel-image {
      background-color: orange;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-size: cover;
      background-position: center;

      .content {
        background-color: white;
        margin: 1rem;
        padding: 1rem;
        display: grid;
        grid-template-rows: min-content;
        gap: 1rem;

        .description-title {
          font-weight: 600;
          white-space: pre-wrap;
        }

        .description {
          font-weight: 400;
          white-space: pre-wrap;
        }
      }
    }
  }

  .panels {
    display: grid;
    grid-template-rows: min-content;
    gap: 1rem;

    .panel {
      background-color: white;

      .title {
        border-bottom: 1px solid theme.$grey-lighter;
        padding: 0.5rem 1rem;
        font-size: 20px;
        font-weight: 600;
      }

      .blurb {
        padding: 1rem;
        font-size: 12px;
        white-space: pre-wrap;
      }

      .items {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 1rem;

        .item {
          display: flex;
          flex-direction: column;
          max-width: 30%;
          align-items: center;

          .item-image {
            height: 75px;
            width: 75px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            background-size: cover;
            background-position: center;
          }
          .item-description {
            max-width: 150px;
            text-align: center;
            font-size: 12px;
            font-weight: 600;
          }
        }
      }

      .contacts {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        .contact {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          .contact-image {
            height: 90px;
            width: 90px;
            background-size: cover;
            background-position: center;
          }
          .contact-details {
            display: flex;
            flex-direction: column;

            .name {
              font-weight: 800;
            }
            .detail {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}