@use "theme";

.DialogOverlay {
  background-color: rgb(0 0 0 / 50%);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  background-color: white;
  border-radius: 4px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow-y: hidden;
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th, td {
    text-align: left;
    padding: 0.5rem;
  }
  th {
    background-color: theme.$orange;
  }
}

.DialogContent:focus {
  outline: none;
}

.dialog-header {
  padding-bottom: 25px;
}

.DialogTitle {
  margin: 0;
  font-weight: 600;
  color: black;
  font-size: 20px;
}

.DialogDescription {
  margin: 10px 0 20px;
  color: black;
  font-size: 15px;
  line-height: 1.5;
}

.dialog-scrollable-content {
	overflow-y: auto;
  border-top: 1px solid theme.$grey-lighter;
  border-bottom: 1px solid theme.$grey-lighter;
}

.dialog-footer {
	display: flex;
	margin-top: 25px;
	justify-content: flex-end;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: black;
  position: absolute;
  top: 10px;
  right: 10px;
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.Label {
  font-size: 15px;
  color: black;
  width: 90px;
  text-align: right;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.terms-content {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
  padding: 1rem 0;

	.check-item {
		display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    padding: 0 8px;
    user-select: none;
    gap: 0.5rem;

		.label {
      height: 24px;
      line-height: 24px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &:hover {
        min-height: 24px;
        height:auto;
        line-height: 24px;
        white-space: normal;
        overflow-x: visible; 
      }
    }

		&.selected {
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
    }

		&:hover {
      cursor: pointer;
      background-color: theme.$grey-lightest;
    }

		button[role=checkbox] {
      all: unset;
      background-color: white;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: theme.$orange;
			border: 1px solid theme.$orange;
  
      &:hover {
        border: 1px solid theme.$orange;
      }
    }
	}
}