@use "theme";

@mixin _page() {
  padding-top: 20px;
  background-color: theme.$grey-lightest;
  background-size: cover;
  color: theme.$grey-darker;
  display: grid;
  grid-template-rows: auto 1fr;
  position: relative;
  height: calc(100vh - 75px);

  .topbar {
    margin: 1rem;
    margin-top: 0;
    padding: 16px 20px 16px 12px;
    border-radius: 4px;
    background-color: theme.$white;
    box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, .132),
                0 0.6px 1.8px 0 rgba(0, 0, 0, .108);
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    // .ux-select {
  
    //   button[role=combobox] {
    //     all: unset;
    //     display: inline-flex;
    //     align-items: center;
    //     justify-content: space-between;
    //     width: calc(100% - 20px);
    //     padding: 0 10px;
    //     line-height: 1;
    //     height: 24px;
    //     color: theme.$grey;
    //     border-radius: 5px;

    //     &.selectionMade {
    //       font-weight: 600;
    //       color: theme.$grey-darkest;
    //     }

    //     &:hover {
    //       background-color: theme.$grey-lightest;
    //     }
  
    //     .ui-icon {
    //       padding: 5px;
    //     }
    //   }
    // }

    .ux-filters {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-self: flex-end;
      gap: 0.5rem;
      padding: 0;
      color: theme.$grey !important;

      .ux-filter {

        font-size: 14px;

        .label-container .label {
          display: none;
          //padding-right: 0.5rem;
          //font-weight: normal;
          //font-size: 14px;
        }
        
        .control {
          width: auto;
          border: none;
          
          &.MultiSelectField {
            .field-input-wrapper {
              border-radius: 5px;
              &:hover {
                background-color: theme.$grey-lightestest;
              }
            }

            .options {
              padding: 0.5rem;
              .option {

              }
            }
          }

          &.SelectField .dropdown, &.MultiSelectField .dropdown {
            .field-input-wrapper {
              border: unset;
              .field-input {
                font-size: unset;
                color: theme.$grey !important;
                input {
                  color: theme.$grey !important;
                }
              }
            }
  
            .options {
              background-color: white;
              box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, .132),
              0 0.6px 1.8px 0 rgba(0, 0, 0, .108);
  
              .option {
                background-color: transparent;
                
                &:hover {
                  color: inherit;
                  background-color: theme.$grey-lightestest;
                }
  
                &.selected {
                  color: inherit;
                  background-color: theme.$grey-lightestest;
                }
  
              }
            }
          }
  
          // &.SelectField .dropdown .options {
          //   max-height: 192px;
          //   overflow-y: auto;
          // }
  
        }

      }
    }
  }
  
  .hierarchical-filter-panel {
  }

  &.manage {
    height: 100%;
    padding: 0 5%;
    overflow: auto;
  }

  > .scrollable-context {
    height: 100%;
    overflow-y: auto;

    > .page-content {
      display: grid;
      grid-template-rows: auto auto 1fr auto;
      min-height: 100%;
      //padding: 20px;

      > * {
        overflow-x: hidden;
      }
    }
  }

  .banner-wrapper {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    display: inline-grid;
    margin-top: 30px;
    max-width: 840px;
    min-width: 460px;
    overflow: hidden;

    .banner-title {
      background-color: #000;
      color: #fff;
      display: inline-block;
      font-family: Helvetica Neue,Georgia,serif;
      font-size: 45px;
      font-weight: 400;
      letter-spacing: -.005em;
      line-height: 1.3em;
      padding: 30px 40px;
    }

    .banner-subtitle {
      background-color: #dedede;
      color: #000;
      display: inline-block;
      font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: normal;
      line-height: 1.3em;
      line-height: 40px;
      padding: 16px 40px;
    }
  }

  .title-wrapper {
    width: 100%;
    padding: 0rem 1rem;

    .title {
      @include theme.type-body-16-bold;
      background-color: theme.$black;
      color: theme.$white;
      border-radius: 2px;
      display: inline-block;
      letter-spacing: normal;
      line-height: 24px;
      // margin-top: 30px;
      max-width: 920px;
      min-width: 460px;
      padding: 8px 1rem;
    }
  }

  .page-link {
    @include _link;
  }

  > .panels {
    display: flex;
    flex-flow: column;
    row-gap: 20px;
  }

  .panels {
    padding: 1rem;
    display: grid;
    gap: 1rem;
  }

  .ux-quick-links {
    // TODO extract quicklinks
    background-color: theme.$grey-lightest;
    display: grid;
    max-width: 100%;
    grid-auto-flow: column;
    justify-content: start;

    gap: 20px;
    padding: 20px;
    overflow-x: auto;
    // @include theme.apps-scrollbar--hidden;
  }

}

@mixin _link() {
  padding: 20px;

  a {
    border: none;
    border-radius: 4px;
    @include theme.type-body-12;
    line-height: 1;  // 18px
    padding: 8px 12px;
    //height: 28px;
    //line-height: 28px;
    display: inline-block;
    background-color: theme.$grey-darker;
    color: theme.$grey-contrast;

    &:hover {
      cursor: pointer;
      background-color: theme.$grey-darker;
      color: theme.$grey-darker-contrast;
    }
  }
}

.ux-page {
  @include _page;
}

// .ux-select-portal {
//   //&.hierarchical, &.topbarFilter {
//     border: none;
//     //background-color: green;
//     box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, .132),
//     0 0.6px 1.8px 0 rgba(0, 0, 0, .108);


//     div[role=option] {
//       gap: 0.5rem;
    
//       button[role=checkbox] {
//         border: 1px solid theme.$grey-lighter;
//       }
//     }
//   //}
// }

// .ux-select {
//   &.hierarchical, &.topbarFilter {
//     border: none;
//     //border: 1px solid red;

//     button[role=combobox] {
//       gap: 16px; 
//       //color: theme.$grey-lightest;     

//       &:hover {
//         background-color: theme.$grey-lightest;
//       }

//       // &:focus {
//       //   box-shadow: none;
//       // }

//       // &[data-placeholder] {
//       //   color: theme.$black;
//       // }
//     }
//   }
// }