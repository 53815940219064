@use "theme";

.ux-support-pane {
	background-color: white;
	display: grid;
	grid-template-rows: min-content 1fr;

  .support-pane-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 1rem;
		border-bottom: 1px solid theme.$grey-lighter;

		.title {
			font-size: 18px;
      		line-height: 16px;
			font-weight: 800;
		}
		.action-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 0.5rem;
		}
	}
}