@use "theme";

@mixin hover {
  &:not([disabled]):hover {
    @content;
  }
}

@mixin disabled {
  &[disabled] {
    @content;
  }
}

button.button {
  border: none;
  border-radius: 4px;

  @include theme.type-body-12;
  line-height: 1;  // 18px
  padding: 0 12px;
  height: 28px;

  background-color: theme.$orange;
  color: theme.$orange-contrast;
  box-sizing: border-box;
  
  &.theme-grey {
    background-color: theme.$grey;
    color: theme.$grey-contrast;
  }

  &.theme-dark {
    background-color: theme.$grey-darker;
    color: theme.$grey-contrast;
  }

  &.theme-white {
    background-color: theme.$white;
    color: theme.$white-contrast;
    font-weight: bold;
    border: 1px solid black;
  }

  @include hover {
    cursor: pointer;
    background-color: theme.$grey-darker;
    color: theme.$grey-darker-contrast;
  }

  &:active {
    background-color: theme.$orange;
    transform: scale(0.9);
  }

  @include disabled {
    cursor: not-allowed;
    background-color: theme.$grey-lightest;
    color: theme.$grey;
  }

  &.table {
    //height:
    line-height: 16px;
    padding: 0 8px;
    border-radius: 8px;  // half of line-height/height
  }

  .ui-icon {
    padding-left: 8px;
  }
}
