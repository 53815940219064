@use "theme";

$_width: 240px;

$_background-colour: theme.$grey-lighterer;
$_colour: theme.$grey-dark;
$_colour-alt: theme.$grey-darker;

$_background-colour-dark-mode: theme.$grey-darkest;
$_colour-dark-mode: theme.$white;
$_colour-dark-mode-alt: theme.$grey-lighter;

div.ux-context-pane {
  // theme
  background-color: $_background-colour;
  color: $_colour;

  //border-left: 1px solid theme.$grey-lighter;
  border-right: 1px solid theme.$grey-lighter;


  user-select: none;

  // size
  // padding: 1rem 0;
  min-width: $_width;
  max-width: 280px;
  width: max-content;
  min-height: 400px;
  overflow-y: auto;

  overflow-x: hidden;

  // position / layout
  position: relative;
  //display: inline-grid;
  display: grid;
  grid-row-gap: 5px;
  grid-template-rows: auto auto 1fr auto auto auto;

  @include theme.dark-mode {
    background-color: $_background-colour-dark-mode;
    color: $_colour-dark-mode;
    border-color: theme.$grey-light;
    //border-right: 1px solid theme.$grey-dark;
  }
  
  header {
    margin-top: 1rem;
    padding: 0 13px;
    display: flex;
    flex-direction: column;

    .project-org {
      color: theme.$orange;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 800;
    }
    .project-name {
      color: theme.$orange-dark;
      font-size: 12px;
      line-height: 1.5;
    }
  }

  .hr {
    margin: 0.5rem;
    border-bottom: 1px solid theme.$grey-light;
  }

  section {

    &.menu {
      //overflow-y: hidden;
      //position: relative;
      //display: flex;
      //flex-flow: column;

      align-content: start;
      display: grid;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      //padding: 0 8px;
      position: relative;
      //scrollbar-width: none;
    }

    .menu-item {
      padding: 0 8px;
      overflow: hidden;

      a {
        $_menu-item-height: 30px;
        $_background-colour: transparent;
        $_background-colour-alt: theme.$grey-lightest;
        $_colour: theme.$black;
        $_colour-alt: theme.$grey;

        display: block;
        //padding: 0 8px;

        // theme
        @include theme.type-body-12;
        white-space: nowrap;
        background-color: $_background-colour;
        color: $_colour;
        font-weight: bold;

        // size
        height: $_menu-item-height;
        line-height: $_menu-item-height;
        padding: 0 8px;
        text-overflow: ellipsis;
        overflow: hidden;

        //border
        border-radius: 2px;
        border: 1px solid transparent; // transparent border to avoid shifting content on hover

        &.active,
        &:hover {
          background-color: $_background-colour-alt;
          border-color: theme.$grey-lighter;
        }

        &:hover {
          cursor: pointer;
        }
      }

      //a {
      //  display: block;
      //  padding: 0 8px;
      //}
    }

    // .title {
    //   @include theme.type-body-12;
    //   color: $_colour-alt;
    //   @include theme.dark-mode {
    //     color: $_colour-dark-mode-alt;
    //   }
    //   padding: 8px;
    //   //padding-left: 8px;
    //   //padding-top: 8px;

    //   // divider line
    //   border-top: 1px solid theme.$grey-lighter;
    //   @include theme.dark-mode {
    //     border-color: theme.$grey-dark;
    //   }
    //   display: inline-block;
    //   width: 100%;
    // }

  }

}


