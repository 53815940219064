@use "theme";

$_menu-item-height: 36px;

$_background-colour: transparent;
$_background-colour-alt: theme.$grey-lightest;
$_colour: theme.$black;
$_colour-alt: theme.$grey;

$_background-colour-dark-mode: theme.$grey-darker;
$_colour-dark-mode: theme.$white;
//$_colour-dark-mode-alt: theme.$grey-lighter;

.add-edit-item {
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-gap: 12px;
  font-size: 12px;

  label {
    align-self: center;
  }

  input {
    border: 1px solid theme.$grey-light;
    padding: 4px;
  }

  .action-container {
    display: flex;
    gap: 12px;
  }
}

.max-height-400 {
  max-height: 400px
}

@mixin _menu {
  // size
  overflow-y: auto;
  height: 100%;
  
  // position / layout
  padding: 0 0px;
  display: grid;
  // grid-row-gap: 4px;
  position: relative;
  align-content: start;
  @include theme.apps-scrollbar--hidden;
}

@mixin _menu-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  background-color: $_background-colour;
  color: $_colour;
  
  // size
  height: $_menu-item-height;
  line-height: $_menu-item-height;
  //vertical-align: middle;

  // position & layout
  position: relative;
  text-align: left;
  padding: 0 8px;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  //border
  //border-radius: 2px;
  //border: 1px solid transparent; // transparent border to avoid shifting content on hover
  border-left: 3px solid $_background-colour; // transparent border to avoid shifting content on hover

  //&.active-child {
  //  background-color: grey;
  //  color: white;
  //}

  //&.active {
  //  background-color: black;
  //  color: white;
  //}

  font-weight: bold;

  
  @include theme.type-body-12;
  @include theme.dark-mode {
    color: $_colour-dark-mode;
  }
 
  &.active-child {
    background: theme.$grey-lighter;
  }
  
  &.active-parent {
      border-left: 3px solid theme.$grey;
      background: theme.$grey-lighter;
  }

  &.active {
    background-color: $_background-colour-alt;
    border-color: theme.$grey-lighter;
    border-left: 3px solid theme.$brand-primary;

    @include theme.dark-mode {
      background-color: $_background-colour-dark-mode;
      color: $_colour-dark-mode;
    }
  }

  &:hover {
    background-color: $_background-colour-alt;

    @include theme.dark-mode {
      background-color: $_background-colour-dark-mode;
      color: $_colour-dark-mode;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.level-2,
  &.level-3,
  &[disabled],
  &.disabled {
    font-weight: normal;
  }

  &.level-3 {
    padding-left: 24px;
  }

  &.plus {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &[disabled], &.disabled {
    color: theme.$grey-light;
  }

  .ui-icon {
    display: inline-block;

    &.end {
      position: absolute;
      right: 8px;
    }
  }

  .end .ui-icon {
    position: absolute;
    right: 8px;
  }
}

.ux-menu {
  @include _menu;
}

.ux-menu-item {
  @include _menu-item;
}
