@mixin columns() {
  @include theme.type-body-12;

  .ux-column {
    //width: 120px;
    min-width: 120px; // using dynamic widths for columns tables
    display: contents;

    white-space: nowrap;

    .title {
      font-weight: bold;
    }

    @include column-datatype;
    @include column-alignment;

    &.number.editable > .footer-totals {
      padding-left: 18px;
    }

    >* {
      border-left: $_border-style;
      border-top: $_border-style;
      padding: 0 $_cell_horizontal_padding;
      height: $_cell_height;
      line-height: $_cell_height;

      &:focus-within {
        border-bottom: 1px solid #D04A02;
      }

      &:first-child {
        position: sticky;
        z-index: 50;
        top: 0;
        background-color: white;
        background-clip: padding-box;
      }

      // top / footer
      &:first-child,
      &.footer-totals {
        @include header-theme;
      }

      // bottom
      &:last-child {
        border-bottom: $_border-style;
      }

    }

    // last col
    &:last-child {

      // each col/cell
      >* {
        //border-bottom: $_border-style;
        border-right: $_border-style;
      }
    }

    &.editable>* {
      background-color: theme.$yellow-lighter;
      border-radius: 0;
      border-bottom: none;

      // stop the editable background overriding the first/last
      &:first-child,
      &.footer-totals {
        @include header-theme;
      }

    }
  }
}