
$font-name--pwc-icon-font: "PwC Icon Font";

@mixin font-face--pwc-icon-font {
  @font-face {
    font-family: 'PwC Icon Font';
    font-style: normal;
    font-weight: 400;
    src: url('../../public/assets/fonts/appkit-font.cf34938f29abe5310ebbecc110530022.eot');
    src: url('../../public/assets/fonts/appkit-font.cf34938f29abe5310ebbecc110530022.eot#iefix') format("embedded-opentype"),
    url('../../public/assets/fonts/appkit-font.afe60efb6667141a542a0a07d985166a.woff2') format("woff2"),
    url('../../public/assets/fonts/appkit-font.72df11e0efa2e8c243aa243a7835e2d9.woff') format("woff"),
    url('../../public/assets/fonts/appkit-font.e07e7c6ad67c85d137d8293c95ded33d.ttf') format("truetype"),
    url('../../public/assets/fonts/appkit-font.2b5c34b630d808240a719a7c023f630a.svg#appkit-font') format("svg");
  }
}

