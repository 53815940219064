@use "theme";

.ux-glossary-layout {
	display: grid;

	&.min {
		grid-template-columns: 1fr;
		overflow-y: auto;
	}

	&.max {
		grid-template-columns: 1fr 2fr;
		overflow-y: hidden;
		
		.glossary {
			overflow-y: auto;
		}
	}
}

.glossary {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	padding: 0.5rem;

	.search-box .search-string {
		width: 100%;
	}

	.file-count {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		font-weight: 800;
	}

  .glossary-item {
    border: 1px solid theme.$grey-lighter;
    padding: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;

    &.selected {
      border: 1px solid theme.$orange-light;
		  box-shadow: 0 0 10px -5px theme.$orange-light;
    }

    .glossary-title {
      font-weight: bold;
    }
    .glossary-description {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.glossary-long {
  border-left: 1px solid theme.$grey-lighter;
	gap: 0.5rem;
	padding: 0.5rem;

  .glossary-title {
      font-weight: bold;
    }
  .glossary-long-description {
    white-space: pre-line;
  }
}