

$icons-map--common-only: (
  // alert
  alert-fill: "\e804",
  alert-outline: "\e805",
  // info & help
  information-fill: '\e881',
  information-outline: '\e882',
  help-question-fill: '\e877',
  help-question-outline: '\e878',
  // common actions
  close-fill: '\e836',
  close-outline: '\e837',
  filter-fill: '\e860',
  filter-outline: '\e861',
  grid-view-fill: '\e86a',
  grid-view-outline: '\e86b',
  home-fill: '\e879',
  home-outline: '\e87a',
  expand-fill: '\e85c',
  expand-outline: '\e85d',
  minimize-fill: '\e895',
  minimize-outline: '\e896',
  check-mark-fill: '\e826',
  check-mark-outline: '\e827',
  spinner-outline: '\ea44',
  setting-fill: '\e8bb',
  setting-outline: '\e8bc',
  // files
  file-fill: '\e85e',
  file-outline: '\e85f',
  folder-closed-fill: '\e862',
  folder-closed-outline: '\e863',
  folder-opened-fill: '\e864',
  folder-opened-outline: '\e865',
  // chevron
  down-chevron-circle-fill: '\e84a',
  down-chevron-circle-outline: '\e84b',
  down-chevron-fill: '\e84c',
  down-chevron-outline: '\e84d',
  left-chevron-circle-fill: '\e883',
  left-chevron-circle-outline: '\e884',
  left-chevron-fill: '\e885',
  left-chevron-outline: '\e886',
  right-chevron-circle-fill: '\e8b3',
  right-chevron-circle-outline: '\e8b4',
  right-chevron-fill: '\e8b5',
  right-chevron-outline: '\e8b6',
  up-chevron-circle-fill: '\e8d9',
  up-chevron-circle-outline: '\e8da',
  up-chevron-fill: '\e8db',
  up-chevron-outline: '\e8dc',
  // charts & sparklines
  bar-chart-fill: '\e818',
  bar-chart-outline: '\e819',
  pie-chart-fill: '\e8a7',
  pie-chart-outline: '\e8a8',
  bubble-plot-fill: '\e81c',
  bubble-plot-outline: '\e81d',
  ganttchart-fill: '\e997',
  spider-chart-fill: '\e8c7',
  spider-chart-outline: '\e8c8',
  bridgechart-fill: '\e9d3',
  bargraph-outline: '\ea7d',
  spark-line-fill: '\e8c5',
  spark-line-outline: '\e8c6',
  // circles
  circle-checkmark-fill: '\e828',
  circle-checkmark-outline: '\e829',
  circle-delete-outline: '\e82a',
  circle-delete-fill: '\e82b',
  circle-minus-fill: '\e82c',
  circle-more-fill: '\e82d',
  circle-minus-outline: '\e82e',
  circle-more-outline: '\e82f',
  circle-plus-fill: '\e830',
  circle-plus-outline: '\e831',
  circle-save-fill: '\e832',
  circle-save-outline: '\e833',
);


$icons-map: (
  letter-x: "\78",
  add-user-fill: "\e800",
  add-user-outline: "\e801",
  airplane-fill: "\e802",
  airplane-outline: "\e803",
  alert-fill: "\e804",
  alert-outline: "\e805",
  archive-fill: '\e806',
  archive-outline: '\e807',
  arrow-decrease-fill: '\e808',
  arrow-decrease-outline: '\e809',
  arrow-down-fill: '\e80a',
  arrow-down-outline: '\e80b',
  arrow-increase-fill: '\e80c',
  arrow-increase-outline: '\e80d',
  arrow-left-fill: '\e80e',
  arrow-left-outline: '\e80f',
  arrow-right-fill: '\e810',
  arrow-right-outline: '\e811',
  arrow-up-fill: '\e812',
  arrow-up-outline: '\e813',
  audience-fill: '\e814',
  audience-outline: '\e815',
  avatar-fill: '\e816',
  avatar-outline: '\e817',
  bar-chart-fill: '\e818',
  bar-chart-outline: '\e819',
  brightness-fill: '\e81a',
  brightness-outline: '\e81b',
  bubble-plot-fill: '\e81c',
  bubble-plot-outline: '\e81d',
  building-fill: '\e81e',
  building-outline: '\e81f',
  calendar-fill: '\e820',
  calendar-outline: '\e821',
  camera-fill: '\e822',
  camera-outline: '\e823',
  center-map-fill: '\e824',
  center-map-outline: '\e825',
  check-mark-fill: '\e826',
  check-mark-outline: '\e827',
  circle-checkmark-fill: '\e828',
  circle-checkmark-outline: '\e829',
  circle-delete-outline: '\e82a',
  circle-delete-fill: '\e82b',
  circle-minus-fill: '\e82c',
  circle-more-fill: '\e82d',
  circle-minus-outline: '\e82e',
  circle-more-outline: '\e82f',
  circle-plus-fill: '\e830',
  circle-plus-outline: '\e831',
  circle-save-fill: '\e832',
  circle-save-outline: '\e833',
  clinic-fill: '\e834',
  clinic-outline: '\e835',
  close-fill: '\e836',
  close-outline: '\e837',
  cloud-fill: '\e838',
  cloud-outline: '\e839',
  comment-fill: '\e83a',
  comment-outline: '\e83b',
  compass-fill: '\e83c',
  compass-outline: '\e83d',
  confirmed-fill: '\e83e',
  confirmed-outline: '\e83f',
  convert-outline: '\e840',
  convert-fill: '\e841',
  delete-fill: '\e842',
  delete-outline: '\e843',
  doc-fill: '\e844',
  doc-outline: '\e845',
  door-closed-fill: '\e846',
  door-closed-outline: '\e847',
  door-opened-fill: '\e848',
  door-opened-outline: '\e849',
  down-chevron-circle-fill: '\e84a',
  down-chevron-circle-outline: '\e84b',
  down-chevron-fill: '\e84c',
  down-chevron-outline: '\e84d',
  download-fill: '\e84e',
  download-light-fill: '\e84f',
  upload-light-outline: '\e850',
  download-outline: '\e851',
  downtrend-fill: '\e852',
  downtrend-outline: '\e853',
  duplicate-fill: '\e854',
  duplicate-outline: '\e855',
  edit-fill: '\e856',
  edit-outline: '\e857',
  email-fill: '\e858',
  email-outline: '\e859',
  end-process-fill: '\e85a',
  end-process-outline: '\e85b',
  expand-fill: '\e85c',
  expand-outline: '\e85d',
  file-fill: '\e85e',
  file-outline: '\e85f',
  filter-fill: '\e860',
  filter-outline: '\e861',
  folder-closed-fill: '\e862',
  folder-closed-outline: '\e863',
  folder-opened-fill: '\e864',
  folder-opened-outline: '\e865',
  globe-fill: '\e866',
  globe-map-fill: '\e867',
  globe-map-outline: '\e868',
  globe-outline: '\e869',
  grid-view-fill: '\e86a',
  grid-view-outline: '\e86b',
  hb-3qtr-lft: '\e86c',
  hb-3qtr-rt: '\e86d',
  hb-empty: '\e86e',
  hb-full-dot: '\e86f',
  hb-full: '\e870',
  hb-half-btm: '\e871',
  hb-half-lft: '\e872',
  hb-half-rt: '\e873',
  hb-half-top: '\e874',
  hb-qtr-lft: '\e875',
  hb-qtr-rt: '\e876',
  help-question-fill: '\e877',
  help-question-outline: '\e878',
  home-fill: '\e879',
  home-outline: '\e87a',
  horizontal-more-fill: '\e87b',
  horizontal-more-outline: '\e87c',
  hospital-fill: '\e87d',
  hospital-outline: '\e87e',
  image-fill: '\e87f',
  image-outline: '\e880',
  information-fill: '\e881',
  information-outline: '\e882',
  left-chevron-circle-fill: '\e883',
  left-chevron-circle-outline: '\e884',
  left-chevron-fill: '\e885',
  left-chevron-outline: '\e886',
  like-fill: '\e887',
  like-outline: '\e888',
  link-fill: '\e889',
  link-outline: '\e88a',
  list-view-fill: '\e88b',
  list-view-outline: '\e88c',
  location-fill: '\e88d',
  location-outline: '\e88e',
  log-out-outline: '\e88f',
  login-fill: '\e890',
  login-outline: '\e891',
  logout-fill: '\e892',
  menu-fill: '\e893',
  menu-outline: '\e894',
  minimize-fill: '\e895',
  minimize-outline: '\e896',
  minus-fill: '\e897',
  minus-outline: '\e898',
  move-fill: '\e899',
  move-outline: '\e89a',
  news-fill: '\e89b',
  news-outline: '\e89c',
  notification-fill: '\e89d',
  notification-outline: '\e89e',
  pause-fill: '\e89f',
  pause-outline: '\e8a0',
  pdf-fill: '\e8a1',
  pdf-outline: '\e8a2',
  person-fill: '\e8a3',
  person-outline: '\e8a4',
  physician-fill: '\e8a5',
  physician-outline: '\e8a6',
  pie-chart-fill: '\e8a7',
  pie-chart-outline: '\e8a8',
  plus-fill: '\e8a9',
  plus-outline: '\e8aa',
  ppt-fill: '\e8ab',
  ppt-outline: '\e8ac',
  printer-fill: '\e8ad',
  printer-outline: '\e8ae',
  rating-fill: '\e8af',
  rating-outline: '\e8b0',
  refresh-fill: '\e8b1',
  refresh-outline: '\e8b2',
  right-chevron-circle-fill: '\e8b3',
  right-chevron-circle-outline: '\e8b4',
  right-chevron-fill: '\e8b5',
  right-chevron-outline: '\e8b6',
  save-fill: '\e8b7',
  save-outline: '\e8b8',
  search-fill: '\e8b9',
  search-outline: '\e8ba',
  setting-fill: '\e8bb',
  setting-outline: '\e8bc',
  share-fill: '\e8bd',
  share-outline: '\e8be',
  transform-outline: '\e8bf',
  visitor-outline: '\e8c0',
  pencil-fill: '\e8c1',
  pencil-outline: '\e8c2',
  sorting-fill: '\e8c3',
  sorting-outline: '\e8c4',
  spark-line-fill: '\e8c5',
  spark-line-outline: '\e8c6',
  spider-chart-fill: '\e8c7',
  spider-chart-outline: '\e8c8',
  survey-checkmark-fill: '\e8c9',
  survey-checkmark-outline: '\e8ca',
  survey-fill: '\e8cb',
  survey-outline: '\e8cc',
  table-data-fill: '\e8cd',
  table-data-outline: '\e8ce',
  target-fill: '\e8cf',
  target-outline: '\e8d0',
  thumb-down-fill: '\e8d1',
  thumb-down-outline: '\e8d2',
  thumb-up-fill: '\e8d3',
  thumb-up-outline: '\e8d4',
  time-fill: '\e8d5',
  time-outline: '\e8d6',
  toggle-fill: '\e8d7',
  toggle-outline: '\e8d8',
  up-chevron-circle-fill: '\e8d9',
  up-chevron-circle-outline: '\e8da',
  up-chevron-fill: '\e8db',
  up-chevron-outline: '\e8dc',
  upload-fill: '\e8dd',
  upload-light-fill: '\e8de',
  download-light-outline: '\e8df',
  upload-outline: '\e8e0',
  uptrend-fill: '\e8e1',
  uptrend-outline: '\e8e2',
  venn-ab-horizontal-fill: '\e8e3',
  venn-ab-horizontal-outline: '\e8e4',
  venn-ab-vertical-fill: '\e8e5',
  venn-ab-vertical-outline: '\e8e6',
  venn-abc-fill: '\e8e7',
  venn-abc-outline: '\e8e8',
  venn-abc-reversed-fill: '\e8e9',
  venn-abc-reversed-outline: '\e8ea',
  vertical-more-fill: '\e8eb',
  vertical-more-outline: '\e8ec',
  view-fill: '\e8ed',
  view-outline: '\e8ee',
  volume-on-fill: '\e8ef',
  volume-off-fill: '\e8f0',
  volume-off-outline: '\e8f1',
  volume-on-outline: '\e8f2',
  volume-up-fill: '\e8f3',
  volume-up-outline: '\e8f4',
  xls-fill: '\e8f5',
  xls-outline: '\e8f6',
  tag-fill: '\e8f7',
  calendar-accepted-fill: '\e8f8',
  call-fill: '\e8fa',
  capital-fill: '\e8fb',
  change-fill: '\e8fc',
  community-fill: '\e8fd',
  desktop-fill: '\e8fe',
  digital-transformation-fill: '\e8ff',
  entrepreneur-fill: '\e900',
  family-fill: '\e901',
  fnb-fill: '\e902',
  governance-fill: '\e903',
  grow-revenue-fill: '\e904',
  growth-fill: '\e905',
  idcard-fill: '\e906',
  infinity-fill: '\e907',
  leader-fill: '\e908',
  tag-outline: '\e909',
  management-fill: '\e90a',
  network-fill: '\e90b',
  partership-fill: '\e90c',
  play-fill: '\e90d',
  profitability-fill: '\e90e',
  purpose-fill: '\e90f',
  radio-fill: '\e910',
  revenue-fill: '\e911',
  service-fill: '\e912',
  shopping-bag-fill: '\e913',
  shopping-cart-fill: '\e914',
  sorting-ascendant-fill: '\e915',
  sorting-descendant-fill: '\e916',
  stewardship-fill: '\e917',
  talent-fill: '\e918',
  ticket-fill: '\e919',
  transform-fill: '\e91a',
  visitor-fill: '\e91b',
  wait-fill: '\e91c',
  wealth-fill: '\e91d',
  calendar-accepted-outline: '\e91e',
  calendar-declined-outline: '\e91f',
  call-outline: '\e920',
  capital-outline: '\e921',
  change-outline: '\e922',
  community-outline: '\e923',
  desktop-outline: '\e924',
  digital-transformation-outline: '\e925',
  entrepreneur-outline: '\e926',
  family-outline: '\e927',
  fnb-outline: '\e928',
  governance-outline: '\e929',
  grow-revenue-outline: '\e92a',
  growth-outline: '\e92b',
  idcard-outline: '\e92c',
  infinity-outline: '\e92d',
  leader-outline: '\e92e',
  management-outline: '\e930',
  network-outline: '\e931',
  partership-outline: '\e932',
  play-outline: '\e933',
  profitability-outline: '\e934',
  purpose-outline: '\e935',
  radio-outline: '\e936',
  revenue-outline: '\e937',
  service-outline: '\e938',
  shopping-bag-outline: '\e939',
  shopping-cart-outline: '\e93a',
  sorting-ascendant-outline: '\e93b',
  sorting-descendant-outline: '\e93c',
  stewardship-outline: '\e93d',
  talent-outline: '\e93e',
  ticket-outline: '\e93f',
  location-locked-fill: '\e940',
  location-locked-outline: '\e941',
  wait-outline: '\e942',
  wealth-outline: '\e943',
  xbrl-fill: '\e944',
  workflow-fill: '\e945',
  woman-fill: '\e946',
  windmill-fill: '\e947',
  wifi-fill: '\e948',
  umbrella-fill: '\e949',
  thermometer-fill: '\e94a',
  telecom-fill: '\e94b',
  tanker-fill: '\e94c',
  speedometer-fill: '\e94e',
  signpost-fill: '\e94f',
  shuttlebus-fill: '\e950',
  shoppingbasket-fill: '\e951',
  shower-fill: '\e952',
  ship-fill: '\e953',
  shareios-fill: '\e954',
  service--fill: '\e955',
  serenityspace-fill: '\e956',
  savings-fill: '\e957',
  screenmirroring-fill: '\e958',
  savemoney-fill: '\e959',
  realestate-fill: '\e95a',
  query-fill: '\e95b',
  qrcode-fill: '\e95c',
  projector-fill: '\e95d',
  productivity-fill: '\e95e',
  prescription-pharmacy-fill: '\e95f',
  webcaststudio-fill: '\e960',
  powerplant-fill: '\e961',
  powerport-plug-outlet-fill: '\e962',
  powerbi-fill: '\e963',
  powerbutton-fill: '\e964',
  platform-fill: '\e967',
  payment-fill: '\e968',
  particulates-fill: '\e96a',
  parks-outdoors-fill: '\e96b',
  paperairplanefill: '\e96c',
  openinnewwindow-fill: '\e96d',
  oiltank-fill: '\e96e',
  oilplatform-fill: '\e96f',
  oilpipeline-fill: '\e970',
  notpublished-fill: '\e971',
  office-fill: '\e972',
  noteplus-fill: '\e973',
  notebook-fill: '\e974',
  newsreport-fill: '\e975',
  note-fill: '\e976',
  neighborhood-fill: '\e977',
  mortgagepayment-fill: '\e978',
  networkport-fill: '\e979',
  mortgage-fill: '\e97a',
  microphone-fill: '\e97b',
  menuview-fill: '\e97d',
  meetingroom-fill: '\e97f',
  meeting-fill: '\e980',
  manufacturer-fill: '\e981',
  manandwoman-fill: '\e982',
  marketing-fill: '\e983',
  man-fill: '\e984',
  malesymbol-fill: '\e985',
  lockopen-unlock-fill: '\e986',
  lockers-fill: '\e987',
  lockclosed-locked-fill: '\e988',
  linkunlinked-fill: '\e989',
  lightbulb-fill: '\e98a',
  linkbroken-fill: '\e98b',
  lifeinsurance-fill: '\e98c',
  library-fill: '\e98d',
  library-reports-fill: '\e98e',
  keyboard-fill: '\e98f',
  labs-fill: '\e990',
  layout-fill: '\e991',
  jewelry-fill: '\e992',
  immersivetechnology-fill: '\e993',
  spinner-fill: '\e994',
  hand-fill: '\e995',
  hub-fill: '\e996',
  ganttchart-fill: '\e997',
  genderneutral-fill: '\e998',
  gameroom-fill: '\e999',
  weight-fill: '\e99a',
  waterdrop-fill: '\e99b',
  wallet-fill: '\e99c',
  walkingworkstation-fill: '\e99d',
  typography-fonts-fill: '\e9a0',
  video-fill: '\e9a1',
  truckconnected-fill: '\e9a2',
  truck-fill: '\e9a3',
  transformv-fill: '\e9a4',
  videoconference-fill: '\e9a5',
  foodbeverage-fill: '\e9a6',
  train-railway-fill: '\e9a7',
  folderdelete-fill: '\e9a8',
  folderadd-fill: '\e9a9',
  flag-fill: '\e9aa',
  firstaid-fill: '\e9ab',
  firehose-fill: '\e9ac',
  fireextinguisher-fill: '\e9ad',
  fireexit-fill: '\e9ae',
  femalesymbol-fill: '\e9af',
  extension-fill: '\e9b0',
  exit-fill: '\e9b1',
  engagement-fill: '\e9b2',
  elevator-fill: '\e9b3',
  education-fill: '\e9b4',
  displaypower-fill: '\e9b5',
  disabilityinsurance-fill: '\e9b6',
  dining-fill: '\e9b7',
  digital-techtransformation-fill: '\e9b8',
  dial-medium-fill: '\e9b9',
  dial-low-fill: '\e9ba',
  dial-high-fill: '\e9bb',
  deskchair-fill: '\e9bc',
  debt-free-fill: '\e9bd',
  database-fill: '\e9be',
  data-fill: '\e9bf',
  creditcardpayment-fill: '\e9c0',
  creditcard-fill: '\e9c1',
  cost-dollar-fill: '\e9c2',
  contacts-fill: '\e9c3',
  configuration-fill: '\e9c4',
  cogs-fill: '\e9c5',
  circuitboardbox-fill: '\e9c6',
  circlepercent-fill: '\e9c7',
  chevrondoubledown-fill: '\e9c8',
  carersspace-fill: '\e9ca',
  camera-mic-speakers-fill: '\e9cb',
  calculator-fill: '\e9ce',
  touchscreen-fill: '\e9cf',
  businessservices-printer-fill: '\e9d0',
  bus-publictransportation-fill: '\e9d1',
  bridgechart-fill: '\e9d3',
  box-open-fill: '\e9d4',
  box-fill: '\e9d5',
  bot-fill: '\e9d6',
  boat-fill: '\e9d7',
  beaker-fill: '\e9d8',
  battery-medium-fill: '\e9d9',
  battery-low-fill: '\e9da',
  battery-high-fill: '\e9db',
  automobile-fill: '\e9dc',
  atm-fill: '\e9dd',
  art-themes-fill: '\e9de',
  arrowup-fill: '\e9df',
  arrowright-fill: '\e9e0',
  arrowleft-fill: '\e9e1',
  arrowincrease-fill: '\e9e2',
  arrowdown-fill: '\e9e3',
  arrowdecrease-fill: '\e9e4',
  apparel-fill: '\e9e5',
  xbrl-outline: '\e9e6',
  workflow-outline: '\e9e7',
  woman-outline: '\e9e8',
  windmill-outline: '\e9e9',
  wifi-outline: '\e9ea',
  weight-outline: '\e9eb',
  webcaststudio-outline: '\e9ec',
  waterdrop-outline: '\e9ed',
  wallet-outline: '\e9ee',
  walkingworkstation-outline: '\e9ef',
  videoconference-outline: '\e9f1',
  video-outline: '\e9f2',
  umbrella-outline: '\e9f3',
  typography-fonts-outline: '\e9f4',
  truckconnected-outline: '\e9f5',
  truck-outline: '\e9f6',
  train-railway-outline: '\e9f7',
  touchscreen-outline: '\e9f8',
  thermometer-outline: '\e9f9',
  telecom-outline: '\e9fa',
  tanker-outline: '\e9fb',
  tabledata-outline: '\e9fd',
  star-outline: '\e9ff',
  speedometer-outline: '\ea00',
  signpost-outline: '\ea01',
  shuttlebus-outline: '\ea02',
  shower-outline: '\ea03',
  shoppingbasket-outline: '\ea04',
  ship-outline: '\ea05',
  serenityspace-outline: '\ea06',
  screenmirroring-outline: '\ea07',
  savings-outline: '\ea08',
  savemoney-outline: '\ea09',
  realestate-outline: '\ea0a',
  query-outline: '\ea0b',
  qrcode-outline: '\ea0c',
  projector-outline: '\ea0d',
  productivity-outline: '\ea0e',
  prescription-outline: '\ea0f',
  powerport-plug-outlet-outline: '\ea11',
  powerplant-outline: '\ea12',
  powerbutton-outline: '\ea13',
  powerbi-outline: '\ea14',
  platform-outline: '\ea16',
  payment-outline: '\ea17',
  particulates-outline: '\ea19',
  parks-outdoors-outline: '\ea1a',
  paperairplane-outline: '\ea1b',
  openinnewwindow-outline: '\ea1c',
  oiltank-outline: '\ea1d',
  oilplatform-outline: '\ea1e',
  oilpipeline-outline: '\ea1f',
  office-outline: '\ea20',
  notpublished-outline: '\ea21',
  noteplus-outline: '\ea22',
  notebook-outline: '\ea23',
  note-outline: '\ea24',
  newsreport-outline: '\ea25',
  networkport-outline: '\ea26',
  neighborhood-outline: '\ea27',
  mortgagepayment-outline: '\ea28',
  mortgage-outline: '\ea29',
  microphone-outline: '\ea2a',
  menuview-outline: '\ea2b',
  meetingroom-outline: '\ea2c',
  meeting-outline: '\ea2d',
  marketing-outline: '\ea2e',
  manufacturer-outline: '\ea2f',
  manandwoman-outline: '\ea30',
  man-outline: '\ea32',
  malesymbol-outline: '\ea33',
  lockopen-unlock-outline: '\ea34',
  lockers-outline: '\ea35',
  lockclosed-locked-outline: '\ea36',
  listview-outline: '\ea37',
  linkunlinked-outline: '\ea38',
  linkbroken-outline: '\ea39',
  lightbulb-outline: '\ea3a',
  lifeinsurance-outline: '\ea3b',
  library-reports-outline: '\ea3c',
  library-outline: '\ea3d',
  layout-outline: '\ea3f',
  labs-outline: '\ea40',
  keyboard-outline: '\ea41',
  jewelry-outline: '\ea42',
  immersivetechnology-outline: '\ea43',
  spinner-outline: '\ea44',
  hub-outline: '\ea45',
  hand-outline: '\ea46',
  genderneutral-outline: '\ea47',
  ganttchart-outline: '\ea48',
  gameroom-outline: '\ea49',
  folderdelete-outline: '\ea4a',
  folderadd-outline: '\ea4b',
  flag-outline: '\ea4c',
  firstaid-outline: '\ea4d',
  firehose-outline: '\ea4e',
  fireextinguisher-outline: '\ea4f',
  fireexit-outline: '\ea50',
  femalesymbol-outline: '\ea51',
  extension-outline: '\ea52',
  exit-outline: '\ea53',
  engagement-outline: '\ea54',
  elevator-outline: '\ea55',
  education-outline: '\ea56',
  displaypower-outline: '\ea57',
  disabilityinsurance-outline: '\ea58',
  dining-outline: '\ea59',
  dial-medium-outline: '\ea5a',
  dial-low-outline: '\ea5b',
  dial-high-outline: '\ea5c',
  deskchair-outline: '\ea5d',
  debt-free-outline: '\ea5e',
  database-outline: '\ea5f',
  data-outline: '\ea60',
  creditcardpayment-outline: '\ea61',
  creditcard-outline: '\ea62',
  cost-dollar-outline: '\ea63',
  contacts-outline: '\ea64',
  configuration-outline: '\ea65',
  cogs-outline: '\ea66',
  circuitboardbox-outline: '\ea67',
  circlepercent-outline: '\ea68',
  chevrondoubledown-outline: '\ea69',
  check-hook-outline: '\ea6a',
  carersspace-outline: '\ea6c',
  camera-mic-speakers-outline: '\ea6e',
  calculator-outline: '\ea70',
  businessservices-printer-outline: '\ea71',
  bus-publictransportation-outline: '\ea72',
  bridgechart-outline: '\ea74',
  box-outline: '\ea75',
  box-open-outline: '\ea76',
  bot-outline: '\ea77',
  boat-outline: '\ea78',
  beaker-outline: '\ea79',
  battery-medium-outline: '\ea7a',
  battery-low-outline: '\ea7b',
  battery-high-outline: '\ea7c',
  bargraph-outline: '\ea7d',
  automobile-outline: '\ea7e',
  atm-outline: '\ea7f',
  art-themes-outline: '\ea80',
  arrowup-outline: '\ea81',
  arrowright-outline: '\ea82',
  arrowleft-outline: '\ea83',
  arrowincrease-outline: '\ea84',
  arrowdown-outline: '\ea85',
  arrowdecrease-outline: '\ea86',
  apparel-outline: '\ea87',
  "3by3grid-outline": '\ea88',
  calendar-declined-fill: '\ebcc',
);

// TODO clean up specific icons list:
//  - pull character code from the map above
//  - reduce explicit listing to common icons only (alert, chevron, etc..)
//$icon--letter-x: "\78";
//$icon--add-user-fill: "\e800";
//$icon--add-user-outline: "\e801";
//$icon--airplane-fill: "\e802";
//$icon--airplane-outline: "\e803";
//$icon--alert-fill: "\e804";
//$icon--alert-outline: "\e805";
//$icon--archive-fill: '\e806';
//$icon--archive-outline: '\e807';
//$icon--arrow-decrease-fill: '\e808';
//$icon--arrow-decrease-outline: '\e809';
//$icon--arrow-down-fill: '\e80a';
//$icon--arrow-down-outline: '\e80b';
//$icon--arrow-increase-fill: '\e80c';
//$icon--arrow-increase-outline: '\e80d';
//$icon--arrow-left-fill: '\e80e';
//$icon--arrow-left-outline: '\e80f';
//$icon--arrow-right-fill: '\e810';
//$icon--arrow-right-outline: '\e811';
//$icon--arrow-up-fill: '\e812';
//$icon--arrow-up-outline: '\e813';
//$icon--audience-fill: '\e814';
//$icon--audience-outline: '\e815';
//$icon--avatar-fill: '\e816';
//$icon--avatar-outline: '\e817';
//$icon--bar-chart-fill: '\e818';
//$icon--bar-chart-outline: '\e819';
//$icon--brightness-fill: '\e81a';
//$icon--brightness-outline: '\e81b';
//$icon--bubble-plot-fill: '\e81c';
//$icon--bubble-plot-outline: '\e81d';
//$icon--building-fill: '\e81e';
//$icon--building-outline: '\e81f';
//$icon--calendar-fill: '\e820';
//$icon--calendar-outline: '\e821';
//$icon--camera-fill: '\e822';
//$icon--camera-outline: '\e823';
//$icon--center-map-fill: '\e824';
//$icon--center-map-outline: '\e825';
//$icon--check-mark-fill: '\e826';
//$icon--check-mark-outline: '\e827';
//$icon--circle-checkmark-fill: '\e828';
//$icon--circle-checkmark-outline: '\e829';
//$icon--circle-delete-outline: '\e82a';
//$icon--circle-delete-fill: '\e82b';
//$icon--circle-minus-fill: '\e82c';
//$icon--circle-more-fill: '\e82d';
//$icon--circle-minus-outline: '\e82e';
//$icon--circle-more-outline: '\e82f';
//$icon--circle-plus-fill: '\e830';
//$icon--circle-plus-outline: '\e831';
//$icon--circle-save-fill: '\e832';
//$icon--circle-save-outline: '\e833';
//$icon--clinic-fill: '\e834';
//$icon--clinic-outline: '\e835';
//$icon--close-fill: '\e836';
//$icon--close-outline: '\e837';
//$icon--cloud-fill: '\e838';
//$icon--cloud-outline: '\e839';
//$icon--comment-fill: '\e83a';
//$icon--comment-outline: '\e83b';
//$icon--compass-fill: '\e83c';
//$icon--compass-outline: '\e83d';
//$icon--confirmed-fill: '\e83e';
//$icon--confirmed-outline: '\e83f';
//$icon--convert-outline: '\e840';
//$icon--convert-fill: '\e841';
//$icon--delete-fill: '\e842';
//$icon--delete-outline: '\e843';
//$icon--doc-fill: '\e844';
//$icon--doc-outline: '\e845';
//$icon--door-closed-fill: '\e846';
//$icon--door-closed-outline: '\e847';
//$icon--door-opened-fill: '\e848';
//$icon--door-opened-outline: '\e849';
//$icon--down-chevron-circle-fill: '\e84a';
//$icon--down-chevron-circle-outline: '\e84b';
//$icon--down-chevron-fill: '\e84c';
//$icon--down-chevron-outline: '\e84d';
//$icon--download-fill: '\e84e';
//$icon--download-light-fill: '\e84f';
//$icon--upload-light-outline: '\e850';
//$icon--download-outline: '\e851';
//$icon--downtrend-fill: '\e852';
//$icon--downtrend-outline: '\e853';
//$icon--duplicate-fill: '\e854';
//$icon--duplicate-outline: '\e855';
//$icon--edit-fill: '\e856';
//$icon--edit-outline: '\e857';
//$icon--email-fill: '\e858';
//$icon--email-outline: '\e859';
//$icon--end-process-fill: '\e85a';
//$icon--end-process-outline: '\e85b';
//$icon--expand-fill: '\e85c';
//$icon--expand-outline: '\e85d';
//$icon--file-fill: '\e85e';
//$icon--file-outline: '\e85f';
//$icon--filter-fill: '\e860';
//$icon--filter-outline: '\e861';
//$icon--folder-closed-fill: '\e862';
//$icon--folder-closed-outline: '\e863';
//$icon--folder-opened-fill: '\e864';
//$icon--folder-opened-outline: '\e865';
//$icon--globe-fill: '\e866';
//$icon--globe-map-fill: '\e867';
//$icon--globe-map-outline: '\e868';
//$icon--globe-outline: '\e869';
//$icon--grid-view-fill: '\e86a';
//$icon--grid-view-outline: '\e86b';
//$icon--hb-3qtr-lft: '\e86c';
//$icon--hb-3qtr-rt: '\e86d';
//$icon--hb-empty: '\e86e';
//$icon--hb-full-dot: '\e86f';
//$icon--hb-full: '\e870';
//$icon--hb-half-btm: '\e871';
//$icon--hb-half-lft: '\e872';
//$icon--hb-half-rt: '\e873';
//$icon--hb-half-top: '\e874';
//$icon--hb-qtr-lft: '\e875';
//$icon--hb-qtr-rt: '\e876';
//$icon--help-question-fill: '\e877';
//$icon--help-question-outline: '\e878';
//$icon--home-fill: '\e879';
//$icon--home-outline: '\e87a';
//$icon--horizontal-more-fill: '\e87b';
//$icon--horizontal-more-outline: '\e87c';
//$icon--hospital-fill: '\e87d';
//$icon--hospital-outline: '\e87e';
//$icon--image-fill: '\e87f';
//$icon--image-outline: '\e880';
//$icon--information-fill: '\e881';
//$icon--information-outline: '\e882';
//$icon--left-chevron-circle-fill: '\e883';
//$icon--left-chevron-circle-outline: '\e884';
//$icon--left-chevron-fill: '\e885';
//$icon--left-chevron-outline: '\e886';
//$icon--like-fill: '\e887';
//$icon--like-outline: '\e888';
//$icon--link-fill: '\e889';
//$icon--link-outline: '\e88a';
//$icon--list-view-fill: '\e88b';
//$icon--list-view-outline: '\e88c';
//$icon--location-fill: '\e88d';
//$icon--location-outline: '\e88e';
//$icon--log-out-outline: '\e88f';
//$icon--login-fill: '\e890';
//$icon--login-outline: '\e891';
//$icon--logout-fill: '\e892';
//$icon--menu-fill: '\e893';
//$icon--menu-outline: '\e894';
//$icon--minimize-fill: '\e895';
//$icon--minimize-outline: '\e896';
//$icon--minus-fill: '\e897';
//$icon--minus-outline: '\e898';
//$icon--move-fill: '\e899';
//$icon--move-outline: '\e89a';
//$icon--news-fill: '\e89b';
//$icon--news-outline: '\e89c';
//$icon--notification-fill: '\e89d';
//$icon--notification-outline: '\e89e';
//$icon--pause-fill: '\e89f';
//$icon--pause-outline: '\e8a0';
//$icon--pdf-fill: '\e8a1';
//$icon--pdf-outline: '\e8a2';
//$icon--person-fill: '\e8a3';
//$icon--person-outline: '\e8a4';
//$icon--physician-fill: '\e8a5';
//$icon--physician-outline: '\e8a6';
//$icon--pie-chart-fill: '\e8a7';
//$icon--pie-chart-outline: '\e8a8';
//$icon--plus-fill: '\e8a9';
//$icon--plus-outline: '\e8aa';
//$icon--ppt-fill: '\e8ab';
//$icon--ppt-outline: '\e8ac';
//$icon--printer-fill: '\e8ad';
//$icon--printer-outline: '\e8ae';
//$icon--rating-fill: '\e8af';
//$icon--rating-outline: '\e8b0';
//$icon--refresh-fill: '\e8b1';
//$icon--refresh-outline: '\e8b2';
//$icon--right-chevron-circle-fill: '\e8b3';
//$icon--right-chevron-circle-outline: '\e8b4';
//$icon--right-chevron-fill: '\e8b5';
//$icon--right-chevron-outline: '\e8b6';
//$icon--save-fill: '\e8b7';
//$icon--save-outline: '\e8b8';
//$icon--search-fill: '\e8b9';
//$icon--search-outline: '\e8ba';
//$icon--setting-fill: '\e8bb';
//$icon--setting-outline: '\e8bc';
//$icon--share-fill: '\e8bd';
//$icon--share-outline: '\e8be';
//$icon--transform-outline: '\e8bf';
//$icon--visitor-outline: '\e8c0';
//$icon--pencil-fill: '\e8c1';
//$icon--pencil-outline: '\e8c2';
//$icon--sorting-fill: '\e8c3';
//$icon--sorting-outline: '\e8c4';
//$icon--spark-line-fill: '\e8c5';
//$icon--spark-line-outline: '\e8c6';
//$icon--spider-chart-fill: '\e8c7';
//$icon--spider-chart-outline: '\e8c8';
//$icon--survey-checkmark-fill: '\e8c9';
//$icon--survey-checkmark-outline: '\e8ca';
//$icon--survey-fill: '\e8cb';
//$icon--survey-outline: '\e8cc';
//$icon--table-data-fill: '\e8cd';
//$icon--table-data-outline: '\e8ce';
//$icon--target-fill: '\e8cf';
//$icon--target-outline: '\e8d0';
//$icon--thumb-down-fill: '\e8d1';
//$icon--thumb-down-outline: '\e8d2';
//$icon--thumb-up-fill: '\e8d3';
//$icon--thumb-up-outline: '\e8d4';
//$icon--time-fill: '\e8d5';
//$icon--time-outline: '\e8d6';
//$icon--toggle-fill: '\e8d7';
//$icon--toggle-outline: '\e8d8';
//$icon--up-chevron-circle-fill: '\e8d9';
//$icon--up-chevron-circle-outline: '\e8da';
//$icon--up-chevron-fill: '\e8db';
//$icon--up-chevron-outline: '\e8dc';
//$icon--upload-fill: '\e8dd';
//$icon--upload-light-fill: '\e8de';
//$icon--download-light-outline: '\e8df';
//$icon--upload-outline: '\e8e0';
//$icon--uptrend-fill: '\e8e1';
//$icon--uptrend-outline: '\e8e2';
//$icon--venn-ab-horizontal-fill: '\e8e3';
//$icon--venn-ab-horizontal-outline: '\e8e4';
//$icon--venn-ab-vertical-fill: '\e8e5';
//$icon--venn-ab-vertical-outline: '\e8e6';
//$icon--venn-abc-fill: '\e8e7';
//$icon--venn-abc-outline: '\e8e8';
//$icon--venn-abc-reversed-fill: '\e8e9';
//$icon--venn-abc-reversed-outline: '\e8ea';
//$icon--vertical-more-fill: '\e8eb';
//$icon--vertical-more-outline: '\e8ec';
//$icon--view-fill: '\e8ed';
//$icon--view-outline: '\e8ee';
//$icon--volume-on-fill: '\e8ef';
//$icon--volume-off-fill: '\e8f0';
//$icon--volume-off-outline: '\e8f1';
//$icon--volume-on-outline: '\e8f2';
//$icon--volume-up-fill: '\e8f3';
//$icon--volume-up-outline: '\e8f4';
//$icon--xls-fill: '\e8f5';
//$icon--xls-outline: '\e8f6';
//$icon--tag-fill: '\e8f7';
//$icon--calendar-accepted-fill: '\e8f8';
//$icon--call-fill: '\e8fa';
//$icon--capital-fill: '\e8fb';
//$icon--change-fill: '\e8fc';
//$icon--community-fill: '\e8fd';
//$icon--desktop-fill: '\e8fe';
//$icon--digital-transformation-fill: '\e8ff';
//$icon--entrepreneur-fill: '\e900';
//$icon--family-fill: '\e901';
//$icon--fnb-fill: '\e902';
//$icon--governance-fill: '\e903';
//$icon--grow-revenue-fill: '\e904';
//$icon--growth-fill: '\e905';
//$icon--idcard-fill: '\e906';
//$icon--infinity-fill: '\e907';
//$icon--leader-fill: '\e908';
//$icon--tag-outline: '\e909';
//$icon--management-fill: '\e90a';
//$icon--network-fill: '\e90b';
//$icon--partership-fill: '\e90c';
//$icon--play-fill: '\e90d';
//$icon--profitability-fill: '\e90e';
//$icon--purpose-fill: '\e90f';
//$icon--radio-fill: '\e910';
//$icon--revenue-fill: '\e911';
//$icon--service-fill: '\e912';
//$icon--shopping-bag-fill: '\e913';
//$icon--shopping-cart-fill: '\e914';
//$icon--sorting-ascendant-fill: '\e915';
//$icon--sorting-descendant-fill: '\e916';
//$icon--stewardship-fill: '\e917';
//$icon--talent-fill: '\e918';
//$icon--ticket-fill: '\e919';
//$icon--transform-fill: '\e91a';
//$icon--visitor-fill: '\e91b';
//$icon--wait-fill: '\e91c';
//$icon--wealth-fill: '\e91d';
//$icon--calendar-accepted-outline: '\e91e';
//$icon--calendar-declined-outline: '\e91f';
//$icon--call-outline: '\e920';
//$icon--capital-outline: '\e921';
//$icon--change-outline: '\e922';
//$icon--community-outline: '\e923';
//$icon--desktop-outline: '\e924';
//$icon--digital-transformation-outline: '\e925';
//$icon--entrepreneur-outline: '\e926';
//$icon--family-outline: '\e927';
//$icon--fnb-outline: '\e928';
//$icon--governance-outline: '\e929';
//$icon--grow-revenue-outline: '\e92a';
//$icon--growth-outline: '\e92b';
//$icon--idcard-outline: '\e92c';
//$icon--infinity-outline: '\e92d';
//$icon--leader-outline: '\e92e';
//$icon--management-outline: '\e930';
//$icon--network-outline: '\e931';
//$icon--partership-outline: '\e932';
//$icon--play-outline: '\e933';
//$icon--profitability-outline: '\e934';
//$icon--purpose-outline: '\e935';
//$icon--radio-outline: '\e936';
//$icon--revenue-outline: '\e937';
//$icon--service-outline: '\e938';
//$icon--shopping-bag-outline: '\e939';
//$icon--shopping-cart-outline: '\e93a';
//$icon--sorting-ascendant-outline: '\e93b';
//$icon--sorting-descendant-outline: '\e93c';
//$icon--stewardship-outline: '\e93d';
//$icon--talent-outline: '\e93e';
//$icon--ticket-outline: '\e93f';
//$icon--location-locked-fill: '\e940';
//$icon--location-locked-outline: '\e941';
//$icon--wait-outline: '\e942';
//$icon--wealth-outline: '\e943';
//$icon--xbrl-fill: '\e944';
//$icon--workflow-fill: '\e945';
//$icon--woman-fill: '\e946';
//$icon--windmill-fill: '\e947';
//$icon--wifi-fill: '\e948';
//$icon--umbrella-fill: '\e949';
//$icon--thermometer-fill: '\e94a';
//$icon--telecom-fill: '\e94b';
//$icon--tanker-fill: '\e94c';
//$icon--speedometer-fill: '\e94e';
//$icon--signpost-fill: '\e94f';
//$icon--shuttlebus-fill: '\e950';
//$icon--shoppingbasket-fill: '\e951';
//$icon--shower-fill: '\e952';
//$icon--ship-fill: '\e953';
//$icon--shareios-fill: '\e954';
//$icon--service--fill: '\e955';
//$icon--serenityspace-fill: '\e956';
//$icon--savings-fill: '\e957';
//$icon--screenmirroring-fill: '\e958';
//$icon--savemoney-fill: '\e959';
//$icon--realestate-fill: '\e95a';
//$icon--query-fill: '\e95b';
//$icon--qrcode-fill: '\e95c';
//$icon--projector-fill: '\e95d';
//$icon--productivity-fill: '\e95e';
//$icon--prescription-pharmacy-fill: '\e95f';
//$icon--webcaststudio-fill: '\e960';
//$icon--powerplant-fill: '\e961';
//$icon--powerport-plug-outlet-fill: '\e962';
//$icon--powerbi-fill: '\e963';
//$icon--powerbutton-fill: '\e964';
//$icon--platform-fill: '\e967';
//$icon--payment-fill: '\e968';
//$icon--particulates-fill: '\e96a';
//$icon--parks-outdoors-fill: '\e96b';
//$icon--paperairplanefill: '\e96c';
//$icon--openinnewwindow-fill: '\e96d';
//$icon--oiltank-fill: '\e96e';
//$icon--oilplatform-fill: '\e96f';
//$icon--oilpipeline-fill: '\e970';
//$icon--notpublished-fill: '\e971';
//$icon--office-fill: '\e972';
//$icon--noteplus-fill: '\e973';
//$icon--notebook-fill: '\e974';
//$icon--newsreport-fill: '\e975';
//$icon--note-fill: '\e976';
//$icon--neighborhood-fill: '\e977';
//$icon--mortgagepayment-fill: '\e978';
//$icon--networkport-fill: '\e979';
//$icon--mortgage-fill: '\e97a';
//$icon--microphone-fill: '\e97b';
//$icon--menuview-fill: '\e97d';
//$icon--meetingroom-fill: '\e97f';
//$icon--meeting-fill: '\e980';
//$icon--manufacturer-fill: '\e981';
//$icon--manandwoman-fill: '\e982';
//$icon--marketing-fill: '\e983';
//$icon--man-fill: '\e984';
//$icon--malesymbol-fill: '\e985';
//$icon--lockopen-unlock-fill: '\e986';
//$icon--lockers-fill: '\e987';
//$icon--lockclosed-locked-fill: '\e988';
//$icon--linkunlinked-fill: '\e989';
//$icon--lightbulb-fill: '\e98a';
//$icon--linkbroken-fill: '\e98b';
//$icon--lifeinsurance-fill: '\e98c';
//$icon--library-fill: '\e98d';
//$icon--library-reports-fill: '\e98e';
//$icon--keyboard-fill: '\e98f';
//$icon--labs-fill: '\e990';
//$icon--layout-fill: '\e991';
//$icon--jewelry-fill: '\e992';
//$icon--immersivetechnology-fill: '\e993';
//$icon--spinner-fill: '\e994';
//$icon--hand-fill: '\e995';
//$icon--hub-fill: '\e996';
//$icon--ganttchart-fill: '\e997';
//$icon--genderneutral-fill: '\e998';
//$icon--gameroom-fill: '\e999';
//$icon--weight-fill: '\e99a';
//$icon--waterdrop-fill: '\e99b';
//$icon--wallet-fill: '\e99c';
//$icon--walkingworkstation-fill: '\e99d';
//$icon--typography-fonts-fill: '\e9a0';
//$icon--video-fill: '\e9a1';
//$icon--truckconnected-fill: '\e9a2';
//$icon--truck-fill: '\e9a3';
//$icon--transformv-fill: '\e9a4';
//$icon--videoconference-fill: '\e9a5';
//$icon--foodbeverage-fill: '\e9a6';
//$icon--train-railway-fill: '\e9a7';
//$icon--folderdelete-fill: '\e9a8';
//$icon--folderadd-fill: '\e9a9';
//$icon--flag-fill: '\e9aa';
//$icon--firstaid-fill: '\e9ab';
//$icon--firehose-fill: '\e9ac';
//$icon--fireextinguisher-fill: '\e9ad';
//$icon--fireexit-fill: '\e9ae';
//$icon--femalesymbol-fill: '\e9af';
//$icon--extension-fill: '\e9b0';
//$icon--exit-fill: '\e9b1';
//$icon--engagement-fill: '\e9b2';
//$icon--elevator-fill: '\e9b3';
//$icon--education-fill: '\e9b4';
//$icon--displaypower-fill: '\e9b5';
//$icon--disabilityinsurance-fill: '\e9b6';
//$icon--dining-fill: '\e9b7';
//$icon--digital-techtransformation-fill: '\e9b8';
//$icon--dial-medium-fill: '\e9b9';
//$icon--dial-low-fill: '\e9ba';
//$icon--dial-high-fill: '\e9bb';
//$icon--deskchair-fill: '\e9bc';
//$icon--debt-free-fill: '\e9bd';
//$icon--database-fill: '\e9be';
//$icon--data-fill: '\e9bf';
//$icon--creditcardpayment-fill: '\e9c0';
//$icon--creditcard-fill: '\e9c1';
//$icon--cost-dollar-fill: '\e9c2';
//$icon--contacts-fill: '\e9c3';
//$icon--configuration-fill: '\e9c4';
//$icon--cogs-fill: '\e9c5';
//$icon--circuitboardbox-fill: '\e9c6';
//$icon--circlepercent-fill: '\e9c7';
//$icon--chevrondoubledown-fill: '\e9c8';
//$icon--carersspace-fill: '\e9ca';
//$icon--camera-mic-speakers-fill: '\e9cb';
//$icon--calculator-fill: '\e9ce';
//$icon--touchscreen-fill: '\e9cf';
//$icon--businessservices-printer-fill: '\e9d0';
//$icon--bus-publictransportation-fill: '\e9d1';
//$icon--bridgechart-fill: '\e9d3';
//$icon--box-open-fill: '\e9d4';
//$icon--box-fill: '\e9d5';
//$icon--bot-fill: '\e9d6';
//$icon--boat-fill: '\e9d7';
//$icon--beaker-fill: '\e9d8';
//$icon--battery-medium-fill: '\e9d9';
//$icon--battery-low-fill: '\e9da';
//$icon--battery-high-fill: '\e9db';
//$icon--automobile-fill: '\e9dc';
//$icon--atm-fill: '\e9dd';
//$icon--art-themes-fill: '\e9de';
//$icon--arrowup-fill: '\e9df';
//$icon--arrowright-fill: '\e9e0';
//$icon--arrowleft-fill: '\e9e1';
//$icon--arrowincrease-fill: '\e9e2';
//$icon--arrowdown-fill: '\e9e3';
//$icon--arrowdecrease-fill: '\e9e4';
//$icon--apparel-fill: '\e9e5';
//$icon--xbrl-outline: '\e9e6';
//$icon--workflow-outline: '\e9e7';
//$icon--woman-outline: '\e9e8';
//$icon--windmill-outline: '\e9e9';
//$icon--wifi-outline: '\e9ea';
//$icon--weight-outline: '\e9eb';
//$icon--webcaststudio-outline: '\e9ec';
//$icon--waterdrop-outline: '\e9ed';
//$icon--wallet-outline: '\e9ee';
//$icon--walkingworkstation-outline: '\e9ef';
//$icon--videoconference-outline: '\e9f1';
//$icon--video-outline: '\e9f2';
//$icon--umbrella-outline: '\e9f3';
//$icon--typography-fonts-outline: '\e9f4';
//$icon--truckconnected-outline: '\e9f5';
//$icon--truck-outline: '\e9f6';
//$icon--train-railway-outline: '\e9f7';
//$icon--touchscreen-outline: '\e9f8';
//$icon--thermometer-outline: '\e9f9';
//$icon--telecom-outline: '\e9fa';
//$icon--tanker-outline: '\e9fb';
//$icon--tabledata-outline: '\e9fd';
//$icon--star-outline: '\e9ff';
//$icon--speedometer-outline: '\ea00';
//$icon--signpost-outline: '\ea01';
//$icon--shuttlebus-outline: '\ea02';
//$icon--shower-outline: '\ea03';
//$icon--shoppingbasket-outline: '\ea04';
//$icon--ship-outline: '\ea05';
//$icon--serenityspace-outline: '\ea06';
//$icon--screenmirroring-outline: '\ea07';
//$icon--savings-outline: '\ea08';
//$icon--savemoney-outline: '\ea09';
//$icon--realestate-outline: '\ea0a';
//$icon--query-outline: '\ea0b';
//$icon--qrcode-outline: '\ea0c';
//$icon--projector-outline: '\ea0d';
//$icon--productivity-outline: '\ea0e';
//$icon--prescription-outline: '\ea0f';
//$icon--powerport-plug-outlet-outline: '\ea11';
//$icon--powerplant-outline: '\ea12';
//$icon--powerbutton-outline: '\ea13';
//$icon--powerbi-outline: '\ea14';
//$icon--platform-outline: '\ea16';
//$icon--payment-outline: '\ea17';
//$icon--particulates-outline: '\ea19';
//$icon--parks-outdoors-outline: '\ea1a';
//$icon--paperairplane-outline: '\ea1b';
//$icon--openinnewwindow-outline: '\ea1c';
//$icon--oiltank-outline: '\ea1d';
//$icon--oilplatform-outline: '\ea1e';
//$icon--oilpipeline-outline: '\ea1f';
//$icon--office-outline: '\ea20';
//$icon--notpublished-outline: '\ea21';
//$icon--noteplus-outline: '\ea22';
//$icon--notebook-outline: '\ea23';
//$icon--note-outline: '\ea24';
//$icon--newsreport-outline: '\ea25';
//$icon--networkport-outline: '\ea26';
//$icon--neighborhood-outline: '\ea27';
//$icon--mortgagepayment-outline: '\ea28';
//$icon--mortgage-outline: '\ea29';
//$icon--microphone-outline: '\ea2a';
//$icon--menuview-outline: '\ea2b';
//$icon--meetingroom-outline: '\ea2c';
//$icon--meeting-outline: '\ea2d';
//$icon--marketing-outline: '\ea2e';
//$icon--manufacturer-outline: '\ea2f';
//$icon--manandwoman-outline: '\ea30';
//$icon--man-outline: '\ea32';
//$icon--malesymbol-outline: '\ea33';
//$icon--lockopen-unlock-outline: '\ea34';
//$icon--lockers-outline: '\ea35';
//$icon--lockclosed-locked-outline: '\ea36';
//$icon--listview-outline: '\ea37';
//$icon--linkunlinked-outline: '\ea38';
//$icon--linkbroken-outline: '\ea39';
//$icon--lightbulb-outline: '\ea3a';
//$icon--lifeinsurance-outline: '\ea3b';
//$icon--library-reports-outline: '\ea3c';
//$icon--library-outline: '\ea3d';
//$icon--layout-outline: '\ea3f';
//$icon--labs-outline: '\ea40';
//$icon--keyboard-outline: '\ea41';
//$icon--jewelry-outline: '\ea42';
//$icon--immersivetechnology-outline: '\ea43';
//$icon--spinner-outline: '\ea44';
//$icon--hub-outline: '\ea45';
//$icon--hand-outline: '\ea46';
//$icon--genderneutral-outline: '\ea47';
//$icon--ganttchart-outline: '\ea48';
//$icon--gameroom-outline: '\ea49';
//$icon--folderdelete-outline: '\ea4a';
//$icon--folderadd-outline: '\ea4b';
//$icon--flag-outline: '\ea4c';
//$icon--firstaid-outline: '\ea4d';
//$icon--firehose-outline: '\ea4e';
//$icon--fireextinguisher-outline: '\ea4f';
//$icon--fireexit-outline: '\ea50';
//$icon--femalesymbol-outline: '\ea51';
//$icon--extension-outline: '\ea52';
//$icon--exit-outline: '\ea53';
//$icon--engagement-outline: '\ea54';
//$icon--elevator-outline: '\ea55';
//$icon--education-outline: '\ea56';
//$icon--displaypower-outline: '\ea57';
//$icon--disabilityinsurance-outline: '\ea58';
//$icon--dining-outline: '\ea59';
//$icon--dial-medium-outline: '\ea5a';
//$icon--dial-low-outline: '\ea5b';
//$icon--dial-high-outline: '\ea5c';
//$icon--deskchair-outline: '\ea5d';
//$icon--debt-free-outline: '\ea5e';
//$icon--database-outline: '\ea5f';
//$icon--data-outline: '\ea60';
//$icon--creditcardpayment-outline: '\ea61';
//$icon--creditcard-outline: '\ea62';
//$icon--cost-dollar-outline: '\ea63';
//$icon--contacts-outline: '\ea64';
//$icon--configuration-outline: '\ea65';
//$icon--cogs-outline: '\ea66';
//$icon--circuitboardbox-outline: '\ea67';
//$icon--circlepercent-outline: '\ea68';
//$icon--chevrondoubledown-outline: '\ea69';
//$icon--check-hook-outline: '\ea6a';
//$icon--carersspace-outline: '\ea6c';
//$icon--camera-mic-speakers-outline: '\ea6e';
//$icon--calculator-outline: '\ea70';
//$icon--businessservices-printer-outline: '\ea71';
//$icon--bus-publictransportation-outline: '\ea72';
//$icon--bridgechart-outline: '\ea74';
//$icon--box-outline: '\ea75';
//$icon--box-open-outline: '\ea76';
//$icon--bot-outline: '\ea77';
//$icon--boat-outline: '\ea78';
//$icon--beaker-outline: '\ea79';
//$icon--battery-medium-outline: '\ea7a';
//$icon--battery-low-outline: '\ea7b';
//$icon--battery-high-outline: '\ea7c';
//$icon--bargraph-outline: '\ea7d';
//$icon--automobile-outline: '\ea7e';
//$icon--atm-outline: '\ea7f';
//$icon--art-themes-outline: '\ea80';
//$icon--arrowup-outline: '\ea81';
//$icon--arrowright-outline: '\ea82';
//$icon--arrowleft-outline: '\ea83';
//$icon--arrowincrease-outline: '\ea84';
//$icon--arrowdown-outline: '\ea85';
//$icon--arrowdecrease-outline: '\ea86';
//$icon--apparel-outline: '\ea87';
//$icon--3by3grid-outline: '\ea88';
//$icon--calendar-declined-fill: '\ebcc';
