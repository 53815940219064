@use "theme";

.ux-toggle {
  display: flex;
  align-items: center;

  button[role=checkbox] {
    all: unset;
    background-color: white;
    width: 17px;
    height: 17px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid theme.$grey-light;
    color: theme.$orange;

    &:hover {
      border: 1px solid theme.$orange;
    }

    &:focus {
      // box-shadow: 0 0 0 2px theme.$orange;
    }

    &[data-disabled] {
      &:hover {
        border: 1px solid theme.$grey-light;
      }

      &:focus {
        // box-shadow: none;
      }
    }
  }
  
  label {
    padding-left: 8px;
    line-height: 1;
    user-select: none;
  }
}