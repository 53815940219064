@use "theme";

.ux-content {
  display: flex;
  gap: 10px;
  align-items: center;  // vertically align

  > div:first-child {
    font-weight: bold;
  }

  > div:last-child {
    border: 1px solid theme.$grey-darker;
    border-radius: 4px;
    padding: 2px 6px;
  }
}

