@use "../fonts" as fonts;

// Font shorthand [https://developer.mozilla.org/en-US/docs/Web/CSS/font]:
//  - font-family
//  - font-size
//  - font-stretch
//  - font-style
//  - font-variant
//  - font-weight
//  - line-height
// NB!

$font-family--charter-itc: fonts.$font-name--helvetica-neue, Georgia, serif;
$font-family--helvetica-neue: fonts.$font-name--helvetica-neue, Helvetica, Arial, sans-serif;
$font-family--pwc-icon-font: fonts.$font-name--pwc-icon-font;  // TODO fallback for pwc-icon-font?

$font-family--titles: $font-family--charter-itc;
//$heading-font-family: "Helvetica Neue Bold", "Helvetica Neue", Arial, sans-serif;
$font-family--body: $font-family--helvetica-neue;

@mixin font-weight($weight) {
  $weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900
  );

  $output: $weight;
  @if map-has-key($weights, $weight) {
    $output: map-get($weights, $weight);
  }

  font-weight: $output;
}

@mixin define-font--no-line-height(
  $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal
) {
  // exclude line-height here since we often override it
  font-size: $font-size;
  font-weight: $font-weight;
  font-family: $font-family;
  letter-spacing: $letter-spacing;
}

@mixin define-font(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: null,
  $letter-spacing: normal
) {
  @include define-font--no-line-height($font-size, $font-weight, $font-family, $letter-spacing);
  line-height: $line-height;
}

/**
 * For designers, we have two custom fonts – ITC Charter for headlines and Helvetica Neue
 * for more heavy copy:
 * - Headers: ITC Charter Regular
 * - Sub-Headers: Helvetica Neue Bold
 * - Body Copy: Helvetica Neue Regular
 * - Callouts/Labels: Helvetica Neue Bold
 * - Numerics: Helvetica Neue Light (in simpler, more informational contexts) and Helvetica Neue Bold (in more graphic, expressive infographics)
 *
 *  //
 *  $font-size,
 *  $line-height: $font-size,
 *  $font-weight: 400,
 *  $font-family: null,
 *  $letter-spacing: normal
 *
 * TODO - Adjust styles to match PwC style guide
 *  https://brand.pwc.com/our-brand/fonts.html
 */
@mixin type-heading-112-itc { // display 1
  @include define-font(112px, 112px, 300, $font-family: $font-family--titles, $letter-spacing: -0.05em);
}

@mixin type-heading-56-itc { // display 2
  @include define-font(56px, 56px, 400, $font-family: $font-family--titles, $letter-spacing: -0.02em);
}

@mixin type-heading-45-itc { // heading 1
  @include define-font(45px, 1.3em, 400, $font-family: $font-family--titles, $letter-spacing: -0.005em);
}

@mixin type-heading-45-itc-bold { // heading 1
  @include type-heading-45-itc;
  font-weight: bold;
}

@mixin type-heading-45 { // heading 1
  @include define-font(45px, 1.3em, 400, $font-family: $font-family--body, $letter-spacing: -0.005em);
}

@mixin type-heading-45-bold { // heading 1
  @include type-heading-45;
  font-weight: bold;
}

@mixin type-heading-38 { // heading 2
  @include define-font(38px, 1.3em, 400, $font-family: $font-family--titles,);
}

@mixin type-heading-38-bold { // heading 2
  @include type-heading-38;
}

@mixin type-heading-28 { // heading 3
  @include define-font(28px, 1.3em, 400, $font-family: $font-family--body,);
}

@mixin type-heading-28-bold { // heading 3
  @include type-heading-28;
  font-weight: bold;
}

@mixin type-heading-24 { // heading 4
  @include define-font(24px, 1.3em, 400, $font-family: $font-family--body,);
}

@mixin type-heading-24-bold { // heading 4
  @include type-heading-24;
  font-weight: bold;
}

@mixin type-heading-20 { // heading 5
  @include define-font(20px, 1.4em, 400, $font-family: $font-family--body,);
}

@mixin type-heading-20-bold { // heading 5
  @include type-heading-20;
  font-weight: bold;
}

@mixin type-body-17 { // context pane title
  @include define-font(17px, 1.0em, 400, $font-family: $font-family--body,);
}

@mixin type-body-17-bold {
  @include type-body-17;
  font-weight: bold;
}

@mixin type-body-16 {
  @include define-font(16px, 1.7em, 400, $font-family: $font-family--body,);
}

@mixin type-body-16-bold {
  @include type-body-16;
  font-weight: bold;
}

@mixin type-body-15 {
  @include define-font(15px, 1.7em, 400, $font-family: $font-family--body,);
}

@mixin type-body-15-bold {
  @include type-body-15;
  font-weight: bold;
}

@mixin type-body-14 {
  @include define-font(14px, 1.0em, 400, $font-family: $font-family--body,);
}

@mixin type-body-14-bold {
  font-weight: bold;
  & {
    @include type-body-14;
  }
}

@mixin type-body-12 {
  @include define-font(12px, 1.2, 400, $font-family: $font-family--body,);
}

@mixin type-body-12-caps {
  @include type-body-12;
  text-transform: uppercase;
}

@mixin type-body-12-bold {
  @include type-body-12;
  font-weight: bold;
}

@mixin type-body-12-bold-caps {
  @include type-body-12-bold;
  text-transform: uppercase;
}

@mixin type-body-10--unofficial {
  @include define-font(10px, 1.2, 400, $font-family: $font-family--body,);
}

@mixin type-body-10-bold-unofficial {
  @include define-font(10px, 1.2, 400, $font-family: $font-family--body,);
  font-weight: bold;
}
