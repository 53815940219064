@use "theme";

@mixin field() {
  //.field-wrapper {
  //  &.text-field { @include _text-field; }
  //  &.number-field { @include _number-field; }
  //}

  .field-input { @include _field-input; }

  .field { @include _field; }
  .field-dense {@include _field_dense; }
  .TextField { @include _text-field; }
  .NumberField { @include _number-field; }
  .SelectField, .MultiSelectField { @include _select-field; }

  .field:before {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 0;
    //left: -20px;
    //bottom: 0;
    //width: 10px;
    // background-color: red;
    z-index: 100;
  }
}

@mixin _field() {
  @include theme.type-body-12;
  position: relative;
  gap: 8px;
  display: grid;
  grid-template-rows: 1fr auto auto;
  margin-bottom: 10px;
  .label-container{
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .label {
      // flex: auto;
      font-weight: bold;
      padding-right: 5px;
      // top: 50%;
    }
    .tooltip{
      flex: auto;
    }
  }

  .description {
    grid-row: 2/3;
    grid-column: span;
  }

  .control {
    grid-row: 3/4;
    grid-column: span;
    border: 1px solid theme.$grey-light;

    &.SelectField {
      border: 0px;
    }
    
    &.MultiSelectField {
      border: 0px;
      .dropdown{
        .field-input-wrapper{
          border: 1px solid theme.$grey-light;
        }
      }
    } 

    &.DownloadFileField {
      border: 0px;

      .ux-select{
        border: 1px solid theme.$grey-light;
      }
    } 

    &.UploadFileField {
      border: 0px;
    } 

    &.BooleanField {
      border: 0px;
    }

    &.DateField {
      border: 0px;
      .date-picker{
        border: 1px solid theme.$grey-light;
        width: 200px;
        .date-picker-closed {

          input{
            @include theme.type-body-12;
            border: 0px;
            padding-left: 10px;
          }
        }

        // .calendar-container {
        //   position: absolute; 
        //   left: 200px;
        //   //bottom: 0px;
        // }
      }
    }   

  }

  > aside {
    position: absolute;
    width: 2px;
    top: 0;
    bottom: 0;
    left: -8px;
    //z-index: 100;
    background-color: theme.$grey-lighter;
    visibility: hidden;  // show on hover
  }

  &:hover > aside {
    visibility: visible;
  }

  > label {
    @include theme.type-body-12-bold;
    // line-height: 18px;
  }

  > .caption,
  > .description {
  }

  .error {
    @include theme.type-body-12;
    color: theme.$red;
    margin-top: 8px;
    font-style: italic;
  }

  .download-button{
    margin-top: 16px;
  }
}

@mixin _field-dense() {
  @include theme.type-body-12;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr;
  grid-auto-rows: 1fr;
  .label-container{
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid theme.$grey-lighter;
    .label {
      font-weight: bold;
      padding-left: 4px;
      padding-right: 4px;
    }
    .tooltip{
      flex: auto;
    }
  }
  .control {
    grid-row: 1/2;
    grid-column: 2/3;
    display: grid;
    background-color: theme.$yellow-lighter;
    border: 1px solid theme.$grey-lighter;
    min-width: 200px;
  
    &.DateField {
      border: 0px;
      .date-picker{
        position:relative;
        background-color: theme.$yellow-lighter;
        .date-picker-closed {

          input{
            @include theme.type-body-12;
            background-color: theme.$yellow-lighter;
            border: 0px;
            padding-left: 10px;
          }
        }

        .calendar-container {
          position: absolute; 
          left: 0px;
          bottom: 0px;
        }
      }
    }   
  
  }
}

.boolean-toggle{
  padding: 4px;
}

@mixin _field-input() {
  // TODO deprecated in favour of `ux/Input`
  @include theme.type-body-14;
  line-height: 1.0;  // text centered vertically with flex layout
  padding-left: 8px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.rounded {
    border-radius: 4px;
  }

  input {
    flex-grow: 1; // take all available space
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
  }
}

@mixin _text-field() {
  .field-input {

  }
}

@mixin _number-field() {
  .field-input {

  }
}

@mixin _select-field() {
  .field-input {
    flex-grow: 1;
    padding-left: 0px;
  }
  .tabs .options {
    @include _select-field-tab-options;
  }
  .dropdown .options {
    width: 100%;
    @include _select-field-dropdown-options;
  }
  .dropdown .hidden {
    display: none;
  }
  .dropdown .field-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    // border: 1px solid theme.$grey-light;
    padding-left: 8px;

    .counter {
      height: 20px;
      min-width: 20px;
      width: auto;
      border-radius: 20px;
      color: theme.$white;
      background-color: theme.$orange;
      text-align: center;
      line-height: 20px;
      // font-size set dynamically based on number in counter, default 12px
    }
    .counter-not-selection {
      height: 20px;
      min-width: 20px;
      width: auto;
      border-radius: 20px;
      color: theme.$white;
      background-color: transparent;
      text-align: center;
      line-height: 20px;
      // font-size set dynamically based on number in counter, default 12px
    }
  }
}

@mixin _select-field-tab-options() {
  //display: flex;
  //flex-flow: row wrap;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  //grid-auto-rows: 40px;
  grid-gap: 10px;
  //grid-auto-flow: column;

  > div {
    //width: 200px;  // figma
    border-radius: 4px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: theme.$grey-lighter;
    user-select: none;

    &.selected {
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
    }

    &:hover {
      cursor: pointer;
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
    }
  }
}

@mixin _select-field-dropdown-options() {
  max-height: 192px; // 8 x 24px options
  overflow-y: auto;
  position: absolute;
  z-index: 2001;

  .option {
    //width: 200px;  // figma
    //border-radius: 4px;
    // height: 24px;
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
    background-color: theme.$grey-lighter;
    user-select: none;
    gap: 0.5rem;

    .label {
      height: 24px;
      line-height: 24px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &.action {
        font-style: italic;
        // prevents italic text getting cut off
        padding-right: 5px;
      }

      &:hover {
        min-height: 24px;
        height:auto;
        line-height: 24px;
        white-space: normal;
        overflow-x: visible; 
      }
    }

    &.selected {
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
    }

    &:hover {
      cursor: pointer;
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
    }

    button[role=checkbox] {
      all: unset;
      background-color: white;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: theme.$orange;
  
      &:hover {
        border: 1px solid theme.$orange;
      }
    }
  }

  .button-container {
    height: auto;
    padding: 8px;
    background-color: theme.$grey-lighter;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
  }
}

@include field();
