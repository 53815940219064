@use "theme";

.ux-card {
  background-color: white;
  height: 280px;
  display: grid;
  transition: all .2s ease-out;
  // grid-template-rows: 3fr 1fr;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  .image {
    background-size: cover;

    height: auto;
    width: auto;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;

    .title {
      @include theme.type-body-16-bold;
      line-height: 18px;
      color: theme.$black;
    }

    .description {
      @include theme.type-body-12;
      color: theme.$black;
    }

    .divider {
      height: 2px;
      background-color: theme.$grey-lightest;
      width: calc(100% + 2rem);
      margin-left: -1rem;
    }

    .long-description {
      @include theme.type-body-12-bold;
      color: theme.$black;
    }

    .blurb {
      @include theme.type-body-12;
      color: theme.$black;
    }
  }

  &.application-card {
    display: grid;
    grid-template-rows: 4fr 2fr;

    &.email {
      .image {
        background-repeat: no-repeat;
        background-size: contain;
        background-color: theme.$grey-lighter;
        background-position-x: center;
      }
    }

    &.hover:hover {
      grid-template-rows: 1fr min-content;
    }
  }

  &.launch-card,
  &.project-card {
    grid-template-rows: 4fr 3fr;

    .content {
      .header-row {
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {
          @include theme.type-body-16-bold;
          color: theme.$black;
        }
      }

      .rows {
        display: flex;
        flex-direction: column;

        .row {
          display: grid;
          grid-template-columns: 30% 70%;

          .label {
            font-size: 10px;
            color: black;
            height: 16px;
          }

          .value {
            font-size: 10px;
            color: black;
            font-weight: bold;
            height: 16px;
          }
        }
      }
    }
  }
}

.ux-card-small {
  // background-color: white;
  height: 140px;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  &.manage-card {
    background-color: white;
    display: grid;
    grid-template-rows: 1fr 6fr;
    grid-template-columns: 1fr;

    .manage-bar {
      background-color: theme.$grey-lighter;
    }

    .content {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 0.5rem;
      height: auto;

      .title {
        @include theme.type-body-16-bold;
        color: theme.$black;
        line-height: 16px;
      }

      .description {
        font-size: 12px;
        line-height: 18px;
        color: black;
        text-align: left;
      }
    }
  }
}

// .app-status {
//   top:0;
//   right:0;
//   z-index: 1;
//   padding: 0px 4px;
//   position: absolute;
//   background-color: black;
//   color: white;
// }