@use "theme";

.user-widget-wrapper {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
}

.user-widget-trigger {
  min-width: 75px;
}

@mixin userwidget() {
    .userwidget { @include _userwidget;}
}

@mixin _userwidget() {
  display: grid;
  grid-template-columns: 50% 50%;
  place-items: center;
  max-height: 50px;
  max-width: 75px;
  // margin: auto;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  border-radius: 100px;  
  cursor: pointer;
}

@include userwidget();

.DropdownMenuContent {
  min-width: 140px;
  background-color: white;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}

.DropdownMenuItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 25px;
  user-select: none;
  outline: none;
}

.DropdownMenuItem[data-highlighted] {
  background-color: theme.$orange;
  color: theme.$orange-contrast
}

.DropdownMenuArrow {
  fill: white;
}
