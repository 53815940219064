
.ux-filters {
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;

  &.primary {
    // padding-top: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

