@use "theme";

.ux-collapsible {
  @include theme.type-body-14;

  >.title {
    @include theme.type-body-16;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: theme.$brand-primary;
  }

  >.collapsible-content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-out;

    &.expanded {
      max-height: 2000px;
      transition: max-height 1.5s ease-out;
    }
  }

}