
$colour-brand-primary-name:     "brand-primary";
$colour-brand-accent-name:      "brand-accent";
$colour-brand-orange-name:      "orange";
$colour-brand-tangerine-name:   "tangerine";
$colour-brand-yellow-name:      "yellow";
$colour-brand-rose-name:        "rose";
$colour-brand-red-name:         "red";
$colour-status-green-name:      "status-green";
$colour-status-yellow-name:     "status-yellow";
$colour-status-red-name:        "status-red";
$colour-success-name:           "status-success";
$colour-warning-name:           "status-warning";
$colour-error-name:             "status-error";
$colour-black-name:             "black";
$colour-grey-darkest-name:      "grey-darkest";
$colour-grey-darker-name:       "grey-darker";
$colour-grey-dark-name:         "grey-dark";
$colour-grey-name:              "grey";
$colour-grey-light-name:        "grey-light";
$colour-grey-lighter-name:      "grey-lighter";
$colour-white-name:             "white";
$colour-vis-purple-name:        "purple";
$colour-vis-blue-name:          "blue";
$colour-vis-green-name:         "green";



// PwC Official Colours
// =====================================================================================================================

// brand greyscale colours
// [https://analyticapps.global.hosting.pwc.com/appkit/content/uiColors#anchor-two]
// [https://appkit.pwc.com/appkit4/content/color?version=angular]

//$grey-darker:          #111111;  // #141414;
//$grey-dark:            #2D2D2D;  // #404041;
//$grey-medium:          #464646;

$black:                #000000;  $black-contrast:                #ffffff;  // use #fff / #000 since vars not created
$grey-darkest:         #111111;  $grey-darkest-contrast:         #ffffff;
$grey-darker:          #252525;  $grey-darker-contrast:          #ffffff;
$grey-dark:            #474747;  $grey-dark-contrast:            #ffffff;
$grey:                 #757575;  $grey-contrast:                 #ffffff;
$grey-light:           #BABABA;  $grey-light-contrast:           $black;  // grey-dark; // #000000;
$grey-lightt:          #C5C5C5;
$grey-lighter:         #D1D1D1;  $grey-lighter-contrast:         $black;  // grey-dark; // #000000;
$grey-lighterer:       #E8E8E8;
$grey-lightest:        #F3F3F3;  $grey-lightest-contrast:        $grey-dark; // #000000;
$grey-lightestest:     #F3F3F3;
$white:                #FFFFFF;  $white-contrast:                $grey-dark; // #000000;

// brand primary / core colours
// Note: these have been confirmed against https://brand.pwc.com/our-brand/colours.html

$orange-darker:        #571F01;  $orange-darker-contrast:        $white;
$orange-dark:          #933401;  $orange-dark-contrast:          $white;
$orange:               #D04A02;  $orange-contrast:               $white;
$orange-light:         #FD6412;  $orange-light-contrast:         $white;
$orange-lighter:       #FEB791;  $orange-lighter-contrast:       $grey-dark; // $black;

$tangerine-darker:     #714300;  $tangerine-darker-contrast:     $white;
$tangerine-dark:       #AE6800;  $tangerine-dark-contrast:       $white;
$tangerine:            #EB8C00;  $tangerine-contrast:            $white;
$tangerine-light:      #FFA929;  $tangerine-light-contrast:      $grey-dark; // $black;
$tangerine-lighter:    #FFDCA9;  $tangerine-lighter-contrast:    $grey-dark; // $black;

$yellow-darker:        #855F00;  $yellow-darker-contrast:        $white;
$yellow-dark:          #C28A00;  $yellow-dark-contrast:          $white;
$yellow:               #FFB600;  $yellow-contrast:               $black; // $black;
$yellow-light:         #FFC83D;  $yellow-light-contrast:         $black; // $black;
$yellow-lighter:       #FFECBD;  $yellow-lighter-contrast:       $grey-dark; // $black;

$red-darker:           #741910;  $red-darker-contrast:           $white;
$red-dark:             #AA2417;  $red-dark-contrast:             $white;
$red:                  #E0301E;  $red-contrast:                  $white;
$red-light:            #E86153;  $red-light-contrast:            $grey-dark; // $black;
$red-lighter:          #F7C8C4;  $red-lighter-contrast:          $grey-dark; // $black;

$rose-darker:          #6E2A35;  $rose-darker-contrast:          $white;
$rose-dark:            #A43E50;  $rose-dark-contrast:            $white;
$rose:                 #D93954;  $rose-contrast:                 $white;
$rose-light:           #E27588;  $rose-light-contrast:           $grey-dark; // $black;
$rose-lighter:         #F1BAC3;  $rose-lighter-contrast:         $grey-dark; // $black;

// primary brand colours collection [orange, tangerine, yellow, red, rose]
$brand-orange: $orange;
$brand-orange-contrast: $white;
$brand-tangerine: $tangerine;
$brand-tangerine-contrast: $white;
$brand-yellow: $yellow;
$brand-yellow-contrast: $grey-dark; // $black;
$brand-red: $red;
$brand-red-contrast: $white;
$brand-rose: $rose;
$brand-rose-contrast: $white;

//$brand-primary: $brand-orange;
//$brand-accent: $brand-yellow;
$brand-primary: $orange;
$brand-primary-contrast: $white;
$brand-accent: $yellow;
$brand-accent-contrast: $grey-dark; // $black;  // TODO standardise how we do this. Palette?
$brand: (
  "primary": $brand-primary,
  "accent": $brand-accent,
  "orange": $brand-orange,
  "tangerine": $brand-tangerine,
  "yellow": $brand-yellow,
  "red": $brand-red,
  "rose": $brand-rose,
);


// AppKit status colours
// [https://analyticapps.global.hosting.pwc.com/appkit/content/uiColors#anchor-three]
// =====================================================================================================================
// #E0301E; (pwc brand red/error)
// #FFB600; (pwc brand yellow/warning)
// #175D2D; (pwc brand green/success)
$status-red:        #C52A1A;   $status-red-contrast:     $white;
$status-error:      #C52A1A;   $status-error-contrast:   $white;
$status-yellow:     #FFBF1F;   $status-yellow-contrast:  $black;
$status-warning:    #FFBF1F;   $status-warning-contrast: $black;
$status-green:      #22992E;   $status-green-contrast:   $white;
$status-success:    #22992E;   $status-success-contrast: $white;

$status: (
  "red": $status-red,
  "error": $status-error,
  "yellow": $status-yellow,
  "warning": $status-warning,
  "green": $status-green,
  "success": $status-success,
);


// PwC Data Visualisation Colours
// Note: these have been confirmed against https://brand.pwc.com/our-brand/colours.html
// =====================================================================================================================

// [https://analyticapps.global.hosting.pwc.com/appkit/content/dataVisualizationColors#anchor-one]

$purple-darker:        #4B20AB;  $purple-darker-contrast:   $white;
$purple-dark:          #6B2CDA;  $purple-dark-contrast:     $white;
$purple:               #8E34F4;  $purple-contrast:          $white;
$purple-light:         #B056F6;  $purple-light-contrast:    $grey-dark; // $black;
$purple-lighter:       #DCB4FC;  $purple-lighter-contrast:  $grey-dark; // $black;

$blue-darker:          #003DAB;  $blue-darker-contrast:     $white;
$blue-dark:            #0060D7;  $blue-dark-contrast:       $white;
$blue:                 #0089EB;  $blue-contrast:            $white;
$blue-light:           #4DACF1;  $blue-light-contrast:      $grey-dark; // $black;
$blue-lighter:         #B3DCF9;  $blue-lighter-contrast:    $grey-dark; // $black;

$green-darker:         #175C2C;  $green-darker-contrast:    $white;
$green-dark:           #2C8646;  $green-dark-contrast:      $white;
$green:                #4eb523;  $green-contrast:           $grey-dark; // $black;
$green-light:          #86DB4F;  $green-light-contrast:     $grey-dark; // $black;
$green-lighter:        #C4FC9F;  $green-lighter-contrast:   $grey-dark; // $black;


// PwC-aligned Material Palettes
// =====================================================================================================================

$dark-palette: (
  darker:   $grey-darker,
  default:  $grey-dark,
  lighter:  $grey,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    default:  $white,
    lighter:  $white,
  )
);

$grey-palette: (
  darker:   $grey-darker,
  dark:     $grey-dark,
  default:  $grey,
  light:    $grey-light,
  lighter:  $grey-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $white,
    light:    $grey-dark,
    lighter:  $grey-dark,
  )
);

$orange-palette: (
  darker:   $orange-darker,
  dark:     $orange-dark,
  default:  $orange,
  light:    $orange-light,
  lighter:  $orange-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $white,
    light:    $grey-dark,
    lighter:  $grey-dark,
  )
);

$tangerine-palette: (
  darker:   $tangerine-darker,
  dark:     $tangerine-dark,
  default:  $tangerine,
  light:    $tangerine-light,
  lighter:  $tangerine-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $white,
    light:    $grey-darker,
    lighter:  $grey-dark,
  )
);

$yellow-palette: (
  darker:   $yellow-darker,
  dark:     $yellow-dark,
  default:  $yellow,
  light:    $yellow-light,
  lighter:  $yellow-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $grey-darker,
    default:  $grey-darker,
    light:    $grey-dark,
    lighter:  $grey-dark,
  )
);

$red-palette: (
  text:     $red,
  darker:   $red-darker,
  dark:     $red-dark,
  default:  $red,
  light:    $red-light,
  lighter:  $red-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $white,
    light:    $white, // $grey-darker,
    lighter:  $white, // $grey-dark,
  )
);

$rose-palette: (
  darker:   $rose-darker,
  dark:     $rose-dark,
  default:  $rose,
  light:    $rose-light,
  lighter:  $rose-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $grey-dark,
    light:    $grey-dark,
    lighter:  $grey-dark,
  )
);

$purple-palette: (
  darker:   $purple-darker,
  dark:     $purple-dark,
  default:  $purple,
  light:    $purple-light,
  lighter:  $purple-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $grey-darker,
    light:    $grey-dark,
    lighter:  $grey-dark,
  )
);

$blue-palette: (
  darker:   $blue-darker,
  dark:     $blue-dark,
  default:  $blue,
  light:    $blue-light,
  lighter:  $blue-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $grey-darker,
    light:    $grey-dark,
    lighter:  $grey-dark,
  )
);

$green-palette: (
  darker:   $green-darker,
  dark:     $green-dark,
  default:  $green,
  light:    $green-light,
  lighter:  $green-lighter,
  // TODO - Check that the contrast is correct
  contrast: (
    darker:   $white,
    dark:     $white,
    default:  $grey-darker,
    light:    $grey-dark,
    lighter:  $grey-dark,
  )
);


// Style classes for use across the app
// =====================================================================================================================

$greyscale-colours-map: (
  "black":         (colour: $black,           contrast: $black-contrast,          ),
  "grey-darkest":  (colour: $grey-darkest,    contrast: $grey-darkest-contrast,   ),
  "grey-darker":   (colour: $grey-darker,     contrast: $grey-darker-contrast,    ),
  "grey-dark":     (colour: $grey-dark,       contrast: $grey-dark-contrast,      ),
  "grey":          (colour: $grey,            contrast: $grey-contrast,           ),
  "grey-light":    (colour: $grey-light,      contrast: $grey-light-contrast,     ),
  "grey-lighter":  (colour: $grey-lighter,    contrast: $grey-lighter-contrast,   ),
  "white":         (colour: $white,           contrast: $white-contrast,          ),
);

$theme-colours-map: (
  "primary":       (colour: $brand-primary,   contrast: $brand-primary-contrast,  ),
  "accent":        (colour: $brand-accent,    contrast: $brand-accent-contrast,   ),
  "brand-primary": (colour: $brand-primary,   contrast: $brand-primary-contrast,  ),
  "brand-accent":  (colour: $brand-accent,    contrast: $brand-accent-contrast,   ),
);

$core-colours-map: (
  "orange":        (colour: $orange,          contrast: $orange-contrast,         ),
  "tangerine":     (colour: $tangerine,       contrast: $tangerine-contrast,      ),
  "yellow":        (colour: $yellow,          contrast: $yellow-contrast,         ),
  "rose":          (colour: $rose,            contrast: $rose-contrast,           ),
  "red":           (colour: $red,             contrast: $red-contrast,            ),
  "brand-orange":    (colour: $orange,          contrast: $orange-contrast,         ),
  "brand-tangerine": (colour: $tangerine,       contrast: $tangerine-contrast,      ),
  "brand-yellow":    (colour: $yellow,          contrast: $yellow-contrast,         ),
  "brand-rose":      (colour: $rose,            contrast: $rose-contrast,           ),
  "brand-red":       (colour: $red,             contrast: $red-contrast,            ),
);

$status-colours-map: (
  "success":  (colour: $status-success,  contrast: $status-success-contrast, ),
  "warning":  (colour: $status-warning,  contrast: $status-warning-contrast, ),
  "error":    (colour: $status-error,    contrast: $status-error-contrast,   ),
  "status-green":    (colour: $status-green,    contrast: $status-green-contrast,   ),
  "status-success":  (colour: $status-success,  contrast: $status-success-contrast, ),
  "status-yellow":   (colour: $status-yellow,   contrast: $status-yellow-contrast,  ),
  "status-warning":  (colour: $status-warning,  contrast: $status-warning-contrast, ),
  "status-red":      (colour: $status-red,      contrast: $status-red-contrast,     ),
  "status-error":    (colour: $status-error,    contrast: $status-error-contrast,   ),
);

$visualisation-colours-map: (
  "vis-purple":    (colour: $purple,          contrast: $purple-contrast,         ),
  "vis-blue":      (colour: $blue,            contrast: $blue-contrast,           ),
  "vis-green":     (colour: $green,           contrast: $green-contrast,          ),
);

@function map-collect($maps...) {
  $collection: ();
  @each $map in $maps {
    $collection: map-merge($collection, $map);
  }
  @return $collection;
}

$colours-map: map-collect(
    $theme-colours-map,
    $core-colours-map,
    $greyscale-colours-map,
    $status-colours-map,
    $visualisation-colours-map,
);
//@debug $colours-map;


// Mixins to apply styles in components
// =====================================================================================================================

@function get-colour($colour-pair) {
  @return map-get($colour-pair, "colour");
}

@function get-contrast($colour-pair) {
  @return map-get($colour-pair, "contrast");
}

@mixin set-colour($colour-name) {
  color: get-colour($colours-map);
}

@mixin set-background-and-contrast($colour-name) {
  background-color: get-colour($colours-map);
  color: get-contrast($colours-map);
}

@mixin apply-background-and-contrasts {
  @each $name, $colour-pair in $colours-map {
    // font colour only
    .apps-col-#{$name},
    .apps-colour-#{$name} {
      color: #{get-colour($colour-pair)};
    }

    // background / contrast colour
    .apps-bg-#{$name},
    .apps-background-#{$name} {
      background-color: #{get-colour($colour-pair)};
      color: #{get-contrast($colour-pair)};
    }

    // inverse background / contrast colour
    .apps-bg-#{$name}-inverse,
    .apps-background-#{$name}-inverse {
      background-color: #{get-contrast($colour-pair)};
      color: #{get-colour($colour-pair)};
    }
  }
}

// apply background and contrast styles globally
@include apply-background-and-contrasts;

//@mixin apply-inverse-background-and-contrasts {
//  @each $name, $colour-pair in $colours-map {
//    .apps-bg-#{$name}-inverse,
//    .apps-background-#{$name}-inverse {
//      // swap colour and contrast here since this is the inverse mixin
//      background-color: #{get-contrast($colour-pair)};
//      color: #{get-colour($colour-pair)};
//    }
//  }
//}

