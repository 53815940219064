@use "theme";


.ux-interact-pane {
  
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  min-width: 200px;
  background-color: theme.$white;
  border-left: 1px solid theme.$grey-lighter;

  &.hidden {
    display: none;
  }

  @include theme.dark-mode {
    background-color: theme.$grey-darkest; // dark mode
    color: theme.$grey-darkest-contrast; // dark mode
  }

  @include theme.dark-mode {
    border-left: 1px solid theme.$grey-dark; // dark mode
  }

  > header {
    //background-color: red;
    padding: 0 1rem;
    height: 45px;
    
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;

    border-bottom: 1px solid theme.$grey-lighter;
    
    @include theme.type-body-14-bold;
    @include theme.dark-mode {
      border-bottom: 1px solid theme.$grey-dark; // dark mode
    }

  }

  > .no-filter {
    font-size: medium;
    padding: 1rem
  }

  fieldset { // filter set
    //margin-top: 12px;
    padding-top: 10px;

    legend span { // filter set title (Primary / Other)
      font-weight: bold;
      padding-left: 4px;
      user-select: none;
    }

    legend:hover {
      cursor: pointer;
    }

    > div { // column of filters
      display: flex;
      flex-flow: column;
      row-gap: 10px;
    }
  }

  div.search-wrapper {
    width: 100%;
    display: flex;
    align-items: center;

    label {
      font-weight: bold;
    }

    input[type='search'] {
      margin-left: 12px;
      flex: 1;
      //width: 100%;
      display: inline-block;
      //width: min-content;
      //width: 100%;
    }
  }

  > div.scrollable { // scrollable content (e.g. "main" content of the filter pane)
    min-width: 280px;
    //max-height: 800px;
    overflow: auto;
    display: flex;
    flex-flow: column;
    row-gap: 20px;
    
    @include theme.type-body-12;
    @include theme.apps-scrollbar--hidden;
  }

  > div.button-container {
    // layout
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: left;

    // TODO - style to stay floating above filters at bottom
    // position: absolute;
    // bottom: 0;

    padding: 1rem;
  }

  & + div {
    padding-left: 0; // TODO remove
  }
}

