@use "theme";

.search-box {
  display: flex;
  flex-direction: row;
  border: 1px solid theme.$grey-lighter;
  align-items: center;
  background-color: white;
  padding: 0 0.5rem;
  
  .search-string {
    @include theme.type-body-16;
    color: theme.$black;
    padding-left: 0.5rem;
    height:40px;
    line-height: 40px;
    border: none;
    outline: none;
  }
}
