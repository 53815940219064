@use "theme";

tr.current > td > .icon-button {
color: orange;
}

.migrationTable > tbody > tr > td {
        text-overflow: ellipsis;
        overflow : hidden;
        white-space: nowrap;
        max-width: 25vw;
    
        &:hover {
        text-overflow: clip;
        white-space: normal;
        word-break: break-all;
    }
}

.progressMessage {
    font-weight: bold;
    display: inline-block;
    border: 1px dashed theme.$grey-lighter;
    padding: 5px;
    width: max-content;
}