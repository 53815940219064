@use "theme";

@mixin _input() {
  @include theme.type-body-14;
  line-height: 1.0;  // text centered vertically with flex layout
  background-color: white;

  //padding: 4px 0;
  padding-left: 8px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &.rounded {
    border-radius: 4px;
  }

  &.error {
    background-color: theme.$red;
    color: white;

    input {
      color: white;
    }
  }

  .icon-button {
    visibility: hidden;
    // TODO should be visible on mouse-hover AND focus (selected, active editor, etc)
  }

  &:hover {
    .icon-button i {
      visibility: visible;  // show icon on hover
    }
  }

  input {
    @include theme.type-body-12;
    display: flex;
    flex-grow: 1; // take all available space
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0;
    justify-content: flex-end;
    height: 21px;
    line-height: 21px;
  }
}

.ux-input {
  @include _input;
}
