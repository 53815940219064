@use "theme";

.ux-filter {
  @include theme.type-body-12;
  position: relative;
  gap: 8px;
  display: grid;
  grid-template-rows: 1fr auto auto;

  &.primary {
    display: flex;
    flex-direction: row;

    .label {
      font-size: 16px;
    }

    .control {
      width: 250px;

      .dropdown .options {
        width: 250px;
        margin-left: -1px; // to align options dropdown neatly with control
      }
    }
  }

  .label-container{
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .label {
      // flex: auto;
      font-weight: bold;
      padding-right: 5px;
      // top: 50%;
    }
    .tooltip{
      flex: auto;
    }
  }

  .description {
    grid-row: 2/3;
    grid-column: span;
  }

  .control {
    grid-row: 3/4;
    grid-column: span;
    border: 1px solid theme.$grey-light;
    
    &.MultiSelectField {
      border: 0px;
      max-width: 400px;
      .dropdown{
        .field-input-wrapper{
          border: 1px solid theme.$grey-light;
        }
      }
    }

    &.SelectField {
      max-width: 400px;
      .field-input {
        flex-grow: 1;
        padding-left: 0px;
      }
      .dropdown .options {
        max-height: 192px; // 8 x 24px options
        overflow-y: auto;


        .option {
          //width: 200px;  // figma
          //border-radius: 4px;
          // height: 24px;
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          justify-content: start;
          padding: 0 8px;
          background-color: theme.$grey-lighter;
          user-select: none;
          gap: 0.5rem;

          .label {
            height: 24px;
            line-height: 24px;
            white-space: nowrap;
            overflow-x: hidden;
            text-overflow: ellipsis;

            &:hover {
              min-height: 24px;
              height:auto;
              line-height: 24px;
              white-space: normal;
              overflow-x: visible; 
            }
          }

          &.selected {
            background-color: theme.$grey-dark;
            color: theme.$grey-dark-contrast;
          }

          &:hover {
            cursor: pointer;
            background-color: theme.$grey-dark;
            color: theme.$grey-dark-contrast;
          }

          button[role=checkbox] {
            all: unset;
            background-color: white;
            width: 17px;
            height: 17px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: theme.$orange;
        
            &:hover {
              border: 1px solid theme.$orange;
            }
          }
        }
      }
      .dropdown .hidden {
        display: none;
      }
      .dropdown .field-input-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        // border: 1px solid theme.$grey-light;
        padding-left: 8px;
    
        .counter {
          height: 20px;
          min-width: 20px;
          width: auto;
          border-radius: 20px;
          color: theme.$white;
          background-color: theme.$orange;
          text-align: center;
          line-height: 20px;
          // font-size set dynamically based on number in counter, default 12px
        }
        .counter-not-selection {
          height: 20px;
          min-width: 20px;
          width: auto;
          border-radius: 20px;
          color: theme.$white;
          background-color: transparent;
          text-align: center;
          line-height: 20px;
          // font-size set dynamically based on number in counter, default 12px
        }
      }
    }
  }

  .error {
    @include theme.type-body-12;
    color: theme.$red;
    margin-top: 8px;
    font-style: italic;
  }
}

// .boolean-toggle{
//   padding: 4px;
// }