@mixin rows() {
  @include theme.type-body-12;

  .ux-column {
    display: contents;

    white-space: nowrap;

    .title {
      font-weight: bold;
      text-align: left;
    }

    @include column-datatype;
    @include column-alignment;

    // each cell
    >* {
      border-left: $_border-style;
      border-top: $_border-style;
      padding: 0 $_cell_horizontal_padding;
      height: $_cell_height;
      line-height: $_cell_height;

      //width: $_cell_width; // on pinned transposed tables width is fixed to align the pinned cols with the cols below
      //min-width: 120px;

      &:focus-within {
        border-bottom: 1px solid #D04A02;
      }

      &:first-child {
        position: sticky;
        z-index: 49;
        left: 0;
      }

      // left col, footer on right
      &:first-child,
      &.footer-totals {
        @include header-theme;
      }

      // right col
      &:last-child {
        border-right: $_border-style;
      }
    }

    // last row
    &:last-child {
      >* {
        border-bottom: $_border-style;
      }
    }

    &.editable>* {
      background-color: theme.$yellow-lighter;

      // stop the editable background overriding the first/last
      &:first-child,
      &.footer-totals {
        @include header-theme;
      }
    }

  }
}