@use "theme";

.breadcrumbs {
  display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
	padding: 1rem calc(5% + 20px);
	font-weight: 800;
  background-color: theme.$grey-lightest;

	&.small {
		font-size: small;
		gap: 0.5rem;
	}

	.hover:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}