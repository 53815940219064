.application-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .application-card {
    width: 100%;
    display: flex;
    //flex-wrap: wrap;
    //grid-template-columns: min-content 1fr min-content min-content;
    flex-direction: row;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    justify-content: space-between;
    box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132);
  
    .key-image {
      height: 120px;
      width: 180px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-size: cover;
      background-position: center;
    }
  
    .application-content {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex-grow: 1;
  
      .heading {
        font-size: 20px;
        font-weight: 800;
      }
  
      .description {
        font-size: 16px;
      }
  
      .pill-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
  
        .pill {
          width: min-content;
          border-radius: 25px;
          padding: 0px 10px;
          text-wrap: nowrap;
          background-color: #f3f3f3;
          color: black;
  
          &.active {
            background-color: #d75306;
            color: white;
          }
        }
      }
  
      hr {
        width: 100%;
      }
  
      .benefits {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1rem;
  
        .benefit {
          display: flex;
          flex-direction: column;
          max-width: 30%;
          align-items: center;
  
          .benefit-image {
            height: 75px;
            width: 75px;
            background-size: cover;
          }
  
          .benefit-description {
            max-width: 250px;
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
  
      .keyContacts {
        padding: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 6rem;
  
        .keyContact {
          display: flex;
          flex-direction: column;
          max-width: 30%;
          justify-content: flex-end;
  
          .keyContact-image {
            height: 75px;
            width: 75px;
            background-size: cover;
          }
  
          .keyContactName {
            font-weight: 800;
          }
        }
      }
    }
  }
  

}


