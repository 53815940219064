@use "theme";

$_border-style: 1px solid theme.$grey-light;
$_border-radius: 4px;

@mixin header-theme() {
  background-color: theme.$grey-dark;
  color: white;
}

@mixin form() {
  @include theme.type-body-12;
  background-color: white;
  padding: 16px;
  display: grid;
  gap: 8px;
  align-content: start;

  > .title {
    @include theme.type-body-14-bold;
  }

  > .caption,
  > .description {
    white-space: pre-line;
  }

  > .actions {
    display: flex;
    flex-flow: row wrap;
    gap: 8px;
    //margin-top: 8px;
  }

  > .dense {
    // border-collapse: collapse;
    // border-spacing: 0;
    border: $_border-style;
    border-radius: $_border-radius;
    border-top: 5px solid theme.$black;
    border-bottom: 5px solid theme.$black;
    
      .field-dense {
        .control {
          
          .dropdown {
              position: relative;
              
            .options {
              position: absolute;
              // right: 0px;
              z-index: 1;
            }
          }
        }
      }
    
  
    }

    .footer {
      height: 4px;
      background-color: theme.$grey-dark;
      color: white;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      > * {
        &:first-child {
          border-bottom-left-radius: 4px;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
        }
      }
    }
    
    .row {
      outline: 0.5px solid theme.$grey-light;
      height: 24px;
      
      .title {
        // @include theme.type-body-12-bold;
        line-height: 24px;
        padding: 0 6px;
      }

      .editable {
        background-color: theme.$yellow-lighter;
        border-left: $_border-style;

        .ux-select button[role=combobox] {
          border: none;
          background-color: theme.$yellow-lighter;
        }

        .TextField .field-input {
          border: none;
          background-color: theme.$yellow-lighter;
        }
      }
    }
  }

.ux-form {
  @include form();
}
