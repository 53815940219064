@use "theme";
//@use "theme/theme/icons-map" as icons-map;

.ui-icon {
  font-family: theme.$font-family--pwc-icon-font;

  &:before {
    display: inline-block;
    //noinspection CssNoGenericFontName
    font-family: "PwC Icon Font";
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: currentColor;
    //font-size: 20px;
    font-size: inherit;
    font-style: normal;
    //cursor: grab;
  }

  &:hover[class*="ui-hover-icon-"] {
    cursor: pointer;
    //background-color: red;
  }
}

//a:hover,
//button:hover {
//  .ui-icon[class*="ui-hover-icon-"] {
//    cursor: pointer;
//  }
//}

@each $name, $glyph in theme.$icons-map {
  .ui-icon-#{$name}:before {
    content: $glyph;
  }

  a:hover > .ui-hover-icon-#{$name}:before,
  button:hover > .ui-hover-icon-#{$name}:before,
  .ui-hover-icon-#{$name}:hover:before {
    content: $glyph;
  }
}
