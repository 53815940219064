@use "theme";

.select-action-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 6px;
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 6px;
  background-color: white;
  border-top: 1px solid theme.$grey-lightest;

  .select-action-button {
    color: theme.$grey;
    background-color: white;
    padding: 6px;

    &:hover {
      background-color: theme.$grey-lightest;
    }
  }
}

.option {
  height: 24px;
  // overflow-x: hidden;
  // overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 0 !important;
  padding-top: 0 !important;

  >span {
    min-width: 16px;
    border-color: theme.$grey-light;
    background-color: transparent;

    >svg {
      color: theme.$orange;
      background-color: white;
    }
  }
}