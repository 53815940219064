@use "theme";

@import "scss/columns";
@import "scss/rows";
@import "scss/column-alignment";
@import "scss/column-datatype";

$_border-style: 1px solid theme.$grey-light;

//$_border-radius: 4px; - handled in table now

$_cell_width: 120px;
$_cell_height: 24px;
$_cell_horizontal_padding: 6px;

@mixin header-theme() {
  background-color: theme.$grey-dark;
  color: theme.$white;
}


.columns, .columns-scroller, .columns-scroller-nopinned {
  @include columns();
}

.columns {
  // shadow to the right of the pinned columns
  box-shadow: 0 0 5px rgba(0,0,0,0.75);
  clip-path: inset(0px -5px 0px 0px);
}

.rows, .rows-scroller, .rows-scroller-nopinned {
  padding-right: 3px; // helps with scrollbar
  @include rows();
}

.ux-table {
  .rows {
    box-shadow: -5px 0 5px rgba(0,0,0,0.75);
    clip-path: inset(0px 0px -5px 0px);
  }
}

.rows, .rows-scroller {
  .ux-column {
    // each cell
    >* {
      width: $_cell_width; // fixed width cells on pinned variants so that the two grids align with each other
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
