
$font-name--charter-itc: "Charter ITC";
//$font-family--charter-itc: "Charter ITC", Georgia, serif;

//@mixin font-family--charter-itc {
//  font-family: $font-family--charter-itc;
//}

@mixin font-face--charter-itc {
  @font-face {
    font-family: 'Charter ITC';
    font-style: normal;
    font-weight: 400;
    src: url('../../public/assets/fonts/6f7f55b5-d511-474f-9652-affbdac0a19e.woff2') format('woff2'),
    url('../../public/assets/fonts/09d1f64e-8b0e-4a6f-8b1c-eafb48e881ef.woff') format('woff'),
    url('../../public/assets/fonts/25b2f50c-1b1a-4239-8ddd-b0b7884e868c.ttf') format('ttf'),
    url('../../public/assets/fonts/a3c5dc0a-ebc3-4e88-8362-81d55b3d9f2b.eot') format('eot');
  }
}
