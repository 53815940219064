
@mixin dark-mode {
  @at-root .lights-out & {
    @content;
  }
}

@mixin light-mode {
  @at-root .lights-on & {
    @content;
  }
}
