@use "theme";

.tag {
  display: inline-block;
  @include theme.type-body-12;
  border-radius: 4px;
  white-space: nowrap;
  //line-height: 14px;
  line-height: 1;
  padding: 0 4px 2px;
  user-select: none;

  margin: 0 4px;

  &:hover {
    cursor: help;
  }

  &.info {
    background-color: theme.$blue-dark;
    color: theme.$blue-contrast;
  }

  &.success {
    background-color: theme.$green-dark;
    color: theme.$green-darker-contrast;
  }

  &.warning {
    background-color: theme.$yellow;
    color: theme.$yellow-contrast;
  }

  &.error {
    background-color: theme.$red;
    color: theme.$red-contrast;
  }
}
