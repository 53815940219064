@use "theme";

.ux-multiselect-checkbox {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	.check-item {
		display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: start;
    padding: 0 8px;
    user-select: none;
    gap: 0.5rem;

		.label {
      height: 24px;
      line-height: 24px;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;

      &:hover {
        min-height: 24px;
        height:auto;
        line-height: 24px;
        white-space: normal;
        overflow-x: visible; 
      }
    }

		&.selected {
      background-color: theme.$grey-dark;
      color: theme.$grey-dark-contrast;
    }

		&:hover {
      cursor: pointer;
      background-color: theme.$grey-lightest;
    }

		button[role=checkbox] {
      all: unset;
      background-color: white;
      width: 17px;
      height: 17px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: theme.$orange;
			border: 1px solid theme.$orange;
  
      &:hover {
        border: 1px solid theme.$orange;
      }
    }
	}
}