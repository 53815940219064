@use "theme";

.date-picker{

    .date-picker-closed {
        display: grid;
        grid-template-columns: 1fr 25px;
        width: 100%;

        .editable-date-picker{
            @include theme.type-body-12;
        }

    }   

    .not-editable-date-picker-closed {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;

        .not-editable-date{
            @include theme.type-body-12;
        }
    }  

    .calendar-container {
        // position: absolute; \\ Set in Field.scss
        // left: 200px;        \\ Set in Field.scss
        // bottom: 0px;        \\ Set in Field.scss
        @include theme.type-body-12;
        border: 1px solid black;
        background-color: white;
        z-index: 2001;
        border-radius: 4px;
        display: grid;
        width: 280px;
        grid-template-columns: 280px;
        grid-template-rows: repeat(8, 30px);
        
        .month-year {
            grid-column-start: 1/2;
            grid-row-start: 1/2;
            display: grid;
            place-items: center;
            grid-template-columns: 35px 20px auto 20px 35px;
            height: 30px;

            * {
                text-align: center;
                font-weight: bold;
            }
        }
        
        .weekdays {
            grid-column-start: 1/2;
            grid-row-start: 2/3;
            display: grid;
            grid-template-columns: repeat(7, 40px);
            grid-template-rows: 30px;
            
            div {
                text-align: center;
                font-weight: bold;
            }
        }
        
        .days {
            grid-column-start: 1/2;
            grid-row-start: 3/4;
            display: grid;
            grid-template-columns: repeat(7, 40px);
            grid-template-rows: repeat(6, 30px);


            .day {
                text-align: center;
                &:hover {
                    cursor: pointer;
                    background-color: theme.$orange; 
                }

                .number-inmonth {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: black;
                    height: 30px;
                    line-height: 30px;
                    &:hover {
                        color: white;
                    }
                }

                .number-outmonth {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    color: theme.$grey-light;
                    height: 30px;
                    line-height: 30px;
                    &:hover {
                        color: white; 
                    }
                }               
            }

            .day-selected {
                background-color: theme.$orange;;
                text-align: center;

                .number-inmonth, .number-outmonth {
                color: white;
                height: 30px;
                line-height: 30px;
                }
                
            }

        }
    }
}