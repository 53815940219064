@use "theme";

.footer-wrapper {
  width: 100%;
  color: theme.$black;
  background-color: theme.$grey-lightest;
  font-size: 14px;

  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr min-content;
  padding: 8px 16px;

  // top inset shadow
  box-shadow: inset 0 7px 9px -7px rgb(0 0 0 / 35%);
  //box-shadow: inset 0 7px 8px -7px rgba(0, 0, 0, 0.25);

  @include theme.dark-mode {
    color: theme.$grey-light; // theme.$grey-lighter;
    background-color: theme.$grey-darkest;
    border-top: 1px solid theme.$grey-dark;
  }

  a {
    color: theme.$orange;
    @include theme.dark-mode {
      color: theme.$tangerine;
    }
  }

  div {
    // padding: 8px;
    margin: 0;
    @include theme.type-body-14;

    line-height: 1.7em;

    a {
      margin: 0 8px;
      white-space: nowrap;

      &:hover {
        text-decoration: underline; // TODO export to global / link style
      }
    }
  }
}
