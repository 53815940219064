@use "theme";

@mixin _quick-link {
  position: relative;
  // width: 256px;
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;

  &:hover {
    cursor: pointer;
    transform: scale(1.01);
  }

  .image {
    width: 160px;
    height: 105px;
    // background-image set dynamically in html
    background-size: cover;
  }

  .title {
    @include theme.type-body-16-bold;
    width: 100%;
    line-height: 1.3;
    color: theme.$black;
  }

  .description {
    @include theme.type-body-12;
    width: 100%;
    line-height: 1.4;
    color: theme.$black;
  }
}

.ux-quick-link {
  @include _quick-link;
}
