@use "theme";

.full-container {
    

    .date-picker-closed{
        display: grid;
        grid-template-columns: 6fr 1fr 2fr;
        
        .input{
        @include theme.type-body-14;
        border: 1px solid black;
        border-radius: 4px;
        }

        .reset {
            @include theme.type-body-14;
            color: white;
            text-align: center;
            border: 1px solid black;
            border-radius: 4px;
            background-color: theme.$orange;
        
        }

    }   


    .calendar-container{
        border: 1px solid black;
        border-radius: 4px;
        display: grid;
        grid-template-columns: 280px;
        grid-template-rows: repeat(8, 30px);
        // position: absolute;
        z-index: 1;
        
        .month-year{
            grid-column-start: 1/2;
            grid-row-start: 1/2;
            display: grid;
            place-items: center;
            grid-template-columns: 35px 20px auto 20px 35px;
            height: 30px;
            
            

            * {
                text-align: center;
                font-weight: bold;
            }

        }
        
        .weekdays{
            grid-column-start: 1/2;
            grid-row-start: 2/3;
            display: grid;
            grid-template-columns: repeat(7, 40px);
            grid-template-rows: 30px;
            
            div {
                // padding: 2px;
                text-align: center;
                font-weight: bold;
            }
        }
        
        .days{
            grid-column-start: 1/2;
            grid-row-start: 3/4;
            display: grid;
            grid-template-columns: repeat(7, 40px);
            grid-template-rows: repeat(6, 30px);
            
            // div {
            //     padding: 2px;
            //     text-align: center;

                .day{
                    text-align: center;
                    &:hover {
                        cursor: pointer;
                        background-color: theme.$orange;
                        border: 1px solid black;
                        
                    }

                    * {
                        .number-inmonth{
                        color: black;
                        }

                        .number-outmonth{
                            color: theme.$grey-light;
                            }
                    }
                }

                .day-selected{
                    background-color: theme.$orange;;
                    text-align: center;
                    border: 1px solid black;

                    * {
                        .number-inmonth, .number-outmonth{
                        color: white;
                        }
                    }
                }

        }
    }
}

