.organisation-card {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  gap: 1rem;
  padding: 1rem;
  background-color: white;
  box-shadow: 0 3.2px 7.2px 0 rgba(0, 0, 0, .132);
  padding-top: 20px;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    padding-top: 1rem;
  }
  
  .subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
  }
  
  .pill-container {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  
  .pill {
    width: min-content;
    border-radius: 25px;
    padding: 0 10px;
    background-color: #D75306;
    color: white;
    white-space: nowrap
  }

  .organisation-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem

  }
}

