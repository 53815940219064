
/* Apps theme global styles */
@use 'theme/theme/colour' as theme-colour;
@use 'theme/theme/typography' as theme-typography;

/* Apps global styles utilities */
@use 'theme/util/index' as util;

/* Fonts */
@use 'theme/fonts/index' as fonts;

/* You can add global styles to this file, and also import other style files */
@include fonts.font-face--charter-itc;
@include fonts.font-face--helvetica-neue;
@include fonts.font-face--pwc-icon-font;

*, *:before, *:after {
  box-sizing: inherit;  // inherit box-sizing from parent; fallback to root (html / body) specified above (border-box)
}

html, body {
  height: 100%;
  box-sizing: border-box; // include padding/borders in width/height
  //color: theme-colour.$grey-dark;

  //@include util.selection-highlight-colour;
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', 'Helvetica Neue Light', sans-serif;
  @include theme-typography.type-body-16(); // apply body font style by default
  background-color: theme-colour.$grey-lightest;
  color: theme-colour.$grey-darker;
}

* {
  accent-color: theme-colour.$orange;
}

h1, h2, h3, h4, h5, h6 {
  color: theme-colour.$black;
}

a {
  color: theme-colour.$orange;
  text-decoration: none;
}

::selection {
  color: white;
  background: theme-colour.$orange;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #e9e9e9;
}

::-webkit-scrollbar-thumb {
  background: #212121;
  -webkit-border-radius: 1ex;
  //-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75); // seems buggy on chrome
}

::-webkit-scrollbar-corner {
  background: #c0c0c0;
}

/* NB! Add global component styles to /src/ui/* rather than including here */
