@use "theme";

.landing {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: grid;
  grid-template-rows: 43px auto 1fr auto;

  .header {
    padding: 0rem 13px;
    display: flex;
    flex-direction: row;
    gap: 13px;
    align-items: center;
    background-color: white;
    border-bottom: 1px solid lightgrey;
  }

  .second-level-menu-container {
    display: grid;
    grid-template-columns: minmax(min-content, 240px) 1fr 240px;
    padding: 2rem 4rem;
    background-color: theme.$grey-lightest;

    .back-navigate {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      font-size: 20px;
      
      .bold {
        white-space: nowrap;
        font-weight: 800;
      }
    }
  }

  .card-container {
    display: grid;
    // minimum card width at 280px
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-auto-rows: min-content;
    grid-auto-flow: dense;
    grid-gap: 2rem;
    padding: 0 4rem 2rem 4rem;
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: theme.$grey-lightest;
  }

  .card-container::-webkit-scrollbar {
    /* Chrome */
    display: none;
  }
}
