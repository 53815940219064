@use "theme";

.loading {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}
