@use "theme";

@mixin _tile() {
  background-color: white;

  @include theme.shadow-z1;

  header {
    align-items: center;
    @include theme.type-body-16-bold;
    padding: 1rem;
    border-bottom: 1px solid theme.$grey-lighter;
  }

  .title {
    display: inline-block;
    color: theme.$black;
    @include theme.dark-mode {
      color: theme.$grey-lightest;
    }

    i[icon].info {
      @include theme.type-body-12;
      padding-bottom: 8px;
      margin-left: 4px;
    }
  }

  .caption {
    @include theme.type-body-12;
    overflow: hidden;
    padding: 0 1rem;
    white-space: pre-wrap;
  }
}

.ux-tile {
  @include _tile();
}
