@use 'theme/theme/colour' as theme;

@mixin apps-scrollbar--hidden {
  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin apps-scrollbar(
  $scrollbar-colour: theme.$grey-lightest,
  $thumb-colour: theme.$grey-dark,
  // TODO $scrollbar-colour-dark-mode: theme.$grey-lightest,
  // TODO $thumb-colour-dark-mode: theme.$grey-dark,
  $rounded: false,
  $hidden: false,
) {

  // webkit-based browsers
  &::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    //background-color: #F5F5F5;
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    background-color: theme.$grey-lightest;
  }
  &::-webkit-scrollbar-thumb {
    background-color: theme.$grey-dark;
    @if rounded {
      border-radius: 3px; // half scrollbar width
    }
  }

  @if hidden {
    @include apps-scrollbar--hidden;
  }
}

//@mixin apps-scrollbar--dark-mode {
//  @include apps-scrollbar(
//    $scrollbar-colour: theme.$grey-darker,
//    $thumb-colour: theme.$grey-lighter,
//    $rounded: true,
//  );
//}

@mixin apps-scrollbar--square {
  @include apps-scrollbar(
    //$scrollbar-colour: theme.$black,
    //$thumb-colour: theme.$grey-lighter,
    $rounded: false,
  );
}

@mixin apps-scrollbar--dark {
  @include apps-scrollbar(
    $scrollbar-colour: theme.$grey-darker,
    $thumb-colour: theme.$grey-lighter,
    $rounded: true,
  );
}

@mixin apps-scrollbar--dark-square {
  @include apps-scrollbar(
    $scrollbar-colour: theme.$grey-darker,
    $thumb-colour: theme.$white,
    $rounded: false,
  );
}
